import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  DatePicker,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { ChevronUpDownIcon } from "@heroicons/react/16/solid";
import { CheckIcon, EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useState, useEffect } from "react";
import {
  startOfWeek,
  startOfMonth,
  parseDate,
  getLocalTimeZone,
  today,
} from "@internationalized/date";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import { Spinner } from "@nextui-org/react";
import { useAddressBook } from "../../contexts/addressHook";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import useDocuments from "../../contexts/documentHook";
import CreateOrder from "./createOrderModal";
import DocumentModal from "./documentModal";

function DropdownMenu({ doc, setCreateOrderOpen }) {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left ml-3">
        <div className="flex flex-col-2 gap-x-1">
          <MenuButton className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon aria-hidden="true" className="h-6 w-6" />
          </MenuButton>
          <p className="text-sm leading-6 text-gray-700">Mer</p>
        </div>

        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {doc?.status === "edited" && (
              <MenuItem>
                <button
                  onClick={() => setCreateOrderOpen(true)}
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                >
                  Skapa order
                </button>
              </MenuItem>
            )}
          </div>
        </MenuItems>
      </Menu>
    </>
  );
}

function AddressDropdown({ prefix, addresses, addressData, setAddressData }) {
  const [query, setQuery] = useState("");
  const loading = false;

  const filteredAddresses =
    query === ""
      ? addresses
      : addresses.filter((address) =>
          address.company?.toLowerCase().includes(query.toLowerCase())
        );

  return (
    <Combobox
      as="div"
      value={addressData}
      onChange={(address) => {
        setQuery("");
        setAddressData({ ...address });
      }}
    >
      <div className="relative">
        {loading && (
          <div className="absolute inset-y-0 mr-4 right-0 flex items-center pr-4">
            <Spinner />
          </div>
        )}
        <ComboboxInput
          className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          id={`${prefix}-company`}
          name={`${prefix}-company`}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Sök företag eller adress"
          onBlur={() => setQuery("")}
          disabled={loading}
          displayValue={(address) => address?.company}
        />
        <ComboboxButton
          className={`absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none ${
            loading ? "pointer-events-none" : ""
          }`}
        >
          {addressData && addressData.company && (
            <button
              className="text-sm mr-2 text-indigo-600 hover:text-indigo-900"
              onClick={() => setAddressData({})}
            >
              Återställ
            </button>
          )}
          <ChevronUpDownIcon
            className="size-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>

        {filteredAddresses.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredAddresses.map((address) => (
              <ComboboxOption
                key={address._id}
                value={address}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
              >
                <div>
                  <span className="block truncate font-medium group-data-[focus]:text-white">
                    {address.company}
                  </span>
                  <span className="block text-sm text-gray-500 group-data-[focus]:text-indigo-200">
                    {address.address1}
                  </span>
                </div>
                {addressData?._id === address._id && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white">
                    <CheckIcon aria-hidden="true" className="h-5 w-5" />
                  </span>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}

function AddressFields({ prefix, addresses, addressData, setAddressData }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddressData({ ...addressData, [name]: value });
  };

  return (
    <div className="-mt-4 grid grid-cols-1 gap-x-2 sm:grid-cols-6">
      <div className="sm:col-span-6 mt-2">
        {addresses?.length > 0 ? (
          <AddressDropdown
            prefix={prefix}
            addresses={addresses}
            addressData={addressData}
            setAddressData={setAddressData}
          />
        ) : (
          <input
            id={`company`}
            name={`company`}
            type="text"
            placeholder="Företag"
            autoComplete="email"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        )}
      </div>

      <div className="sm:col-span-6 mt-2">
        <input
          id={`${prefix}-vatNumber`}
          name={`vatNumber`}
          value={addressData.vatNumber || ""}
          onChange={handleChange}
          type="text"
          placeholder="Momsregistreringsnummer (VAT)"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>

      <div className="sm:col-span-3 mt-2">
        <input
          id={`${prefix}-firstName`}
          name={`firstName`}
          type="text"
          value={addressData.firstName || ""}
          onChange={handleChange}
          placeholder="Förnamn"
          autoComplete="given-name"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>

      <div className="sm:col-span-3 mt-2">
        <input
          id={`${prefix}-lastName`}
          name={`lastName`}
          type="text"
          value={addressData.lastName || ""}
          onChange={handleChange}
          placeholder="Efternamn"
          autoComplete="family-name"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>

      <div className="sm:col-span-6 mt-2">
        <input
          id={`${prefix}-address1`}
          name={`address1`}
          type="text"
          value={addressData.address1 || ""}
          onChange={handleChange}
          placeholder="Adress 1"
          autoComplete="address-line1"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>

      <div className="sm:col-span-6 mt-2">
        <input
          id={`${prefix}-address2`}
          name={`address2`}
          type="text"
          value={addressData.address2 || ""}
          onChange={handleChange}
          placeholder="Adress 2 (valfritt)"
          autoComplete="address-line2"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>

      <div className="sm:col-span-2 mt-2">
        <input
          id={`${prefix}-zip`}
          name={`zip`}
          type="text"
          value={addressData.zip || ""}
          onChange={handleChange}
          placeholder="Postnummer"
          autoComplete="postal-code"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>

      <div className="sm:col-span-4 mt-2">
        <input
          id={`${prefix}-city`}
          name={`city`}
          type="text"
          value={addressData.city || ""}
          onChange={handleChange}
          placeholder="Stad"
          autoComplete="address-level2"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>

      <div className="sm:col-span-3 mt-2">
        <input
          id={`${prefix}-state`}
          name={`state`}
          type="text"
          value={addressData.state || ""}
          onChange={handleChange}
          placeholder="Stat/provins"
          autoComplete="state"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>

      <div className="sm:col-span-3 mt-2">
        <select
          id={`${prefix}-countryCode`}
          name={`countryCode`}
          value={addressData.countryCode || "SE"}
          onChange={(e) =>
            setAddressData({ ...addressData, countryCode: e.target.value })
          }
          autoComplete="country-name"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        >
          <option value="SE">Sverige</option>
          <option value="NO">Norge</option>
          <option value="FI">Finland</option>
        </select>
      </div>

      <div className="sm:col-span-3 mt-2">
        <input
          id={`${prefix}-email`}
          name={`email`}
          type="email"
          value={addressData.email || ""}
          onChange={handleChange}
          placeholder="E-postadress"
          autoComplete="email"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>

      <div className="sm:col-span-3 mt-2">
        <input
          id={`${prefix}-phone`}
          name={`phone`}
          type="tel"
          value={addressData.phone || ""}
          onChange={handleChange}
          placeholder="Telefonnummer"
          autoComplete="tel"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        />
      </div>
    </div>
  );
}

function InvoiceTable({ doc, setDoc }) {
  const [errors, setErrors] = useState([]); // Lagrar valideringsfel

  // Valideringsfunktion
  const validateDocument = () => {
    const newErrors = [];
    doc.products.forEach((product, index) => {
      if (!product.hsCode || !/^\d+$/.test(product.hsCode)) {
        newErrors.push({
          field: "HS-kod",
          index,
          message: "HS-kod måste vara ett nummer.",
        });
      }
      if (!product.quantity || !/^\d+$/.test(product.quantity)) {
        newErrors.push({
          field: "Antal",
          index,
          message: "Antal måste vara ett nummer.",
        });
      }
      if (!product.weight || !/^\d*(\.\d+)?$/.test(product.weight)) {
        newErrors.push({
          field: "Vikt",
          index,
          message: "Vikt måste vara ett decimaltal.",
        });
      }
      // Validera fler fält vid behov...
    });
    setErrors(newErrors);
    return newErrors.length === 0;
  };

  // Hanterar ändringar i fält
  const handleInputChange = (value, field, index) => {
    if (field === "countryOfOrigin" && value.length > 0) {
      value = value.toUpperCase();
    }
    if (field === "weight" || field === "quantity" || field === "price") {
      value = value.replace(",", ".");
      value = value.replace(/[^0-9.]/g, "");
    }
    setDoc((prevDoc) => {
      const updatedProducts = [...prevDoc.products];
      updatedProducts[index] = {
        ...updatedProducts[index],
        [field]: value,
      };
      return {
        ...prevDoc,
        products: updatedProducts,
      };
    });
  };

  // Begränsa inmatning till endast nummer
  const handleNumberInput = (e) => {
    // Tillåt siffror, Backspace, Tab, pilvänster, pilhöger, Enter och klistra in (Ctrl+V eller Cmd+V)
    const allowedKeys = [
      "Backspace",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Enter",
      "Delete",
    ];
    const isCtrlCommand = e.ctrlKey || e.metaKey;

    // Tillåt Ctrl/Cmd + C, V, X
    if (
      !/^\d$/.test(e.key) &&
      !allowedKeys.includes(e.key) &&
      !(isCtrlCommand && ["c", "v", "x"].includes(e.key.toLowerCase()))
    ) {
      e.preventDefault();
    }
  };

  const handleLetterInput = (e) => {
    if (
      !/^[a-zA-ZåäöÅÄÖ]$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  // Hanterar sparande av dokumentet
  const handleSave = () => {
    if (validateDocument()) {
      // Spara dokumentet
      console.log("Dokument sparat!", doc);
    } else {
      console.error("Valideringsfel:", errors);
    }
  };

  const handleDeleteRow = (index) => {
    setDoc((prevDoc) => {
      const updatedProducts = prevDoc.products.filter((_, i) => i !== index);
      return {
        ...prevDoc,
        products: updatedProducts,
      };
    });
  };

  return (
    <div className="flow-root px-4 sm:px-0">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden ring-1 shadow-sm ring-black/5 sm:rounded-lg">
            <table className="table-fixed min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pr-3 pl-4 w-1/12 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Artnr.
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 w-1/6 text-left text-sm font-semibold text-gray-900"
                  >
                    Produkt
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 w-1/12 text-left text-sm font-semibold text-gray-900"
                  >
                    HS-kod
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 w-1/12 text-left text-sm font-semibold text-gray-900"
                  >
                    Ursprung
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 w-1/12 text-left text-sm font-semibold text-gray-900"
                  >
                    Vikt
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 w-1/12 text-left text-sm font-semibold text-gray-900"
                  >
                    Antal
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 w-1/12 text-left text-sm font-semibold text-gray-900"
                  >
                    Pris
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 w-1/12 text-left text-sm font-semibold text-gray-900"
                  >
                    Summa
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 w-1/12 text-left text-sm font-semibold text-gray-900"
                  >
                    Totalvikt
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {doc?.products?.map((product, index) => (
                  <tr
                    key="jesper"
                    className="relative group divide-x divide-gray-200"
                  >
                    <td className="pr-3 pl-4 py-2 w-1/12">
                      <input
                        type="text"
                        value={product.sku || ""}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "sku", index)
                        }
                        className={`block w-full h-full border-none rounded-sm px-2 py-1 text-sm text-gray-900 focus:outline-none focus:ring-offset-4 focus:ring-2 focus:ring-indigo-500 ${
                          product.sku ? "" : "ring-1 ring-red-300 ring-offset-4"
                        }`}
                        placeholder=""
                      />
                      <button
                        onClick={() => handleDeleteRow(index)}
                        className="absolute top-1/2 -translate-y-1/2 right-[-150px] group-hover:right-0 transition-all duration-300 bg-red-500 text-white px-3 py-1 rounded shadow text-xs font-semibold"
                      >
                        Radera
                      </button>
                    </td>
                    <td className="px-3 py-2 w-1/6">
                      <input
                        type="text"
                        value={product.title || ""}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "title", index)
                        }
                        className={`block w-full h-full border-none rounded-sm px-2 py-1 text-sm text-gray-900 focus:outline-none focus:ring-offset-4 focus:ring-2 focus:ring-indigo-500 ${
                          product.title
                            ? ""
                            : "ring-1 ring-red-300 ring-offset-4"
                        }`}
                        placeholder=""
                      />
                    </td>
                    <td className="px-3 py-2 w-1/12">
                      <input
                        type="text"
                        value={product.hsCode || ""}
                        maxLength="8"
                        onKeyDown={handleNumberInput}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "hsCode", index)
                        }
                        className={`block w-full h-full border-none rounded-sm px-2 py-1 text-sm text-gray-900 focus:outline-none focus:ring-offset-4 focus:ring-2 focus:ring-indigo-500 ${
                          product.hsCode
                            ? ""
                            : "ring-1 ring-red-300 ring-offset-4"
                        }`}
                        placeholder=""
                      />
                    </td>
                    <td className="px-3 py-2 w-1/12">
                      <input
                        type="text"
                        value={product.countryOfOrigin || ""}
                        maxLength="2"
                        onKeyDown={handleLetterInput}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            "countryOfOrigin",
                            index
                          )
                        }
                        className={`block w-full h-full border-none rounded-sm px-2 py-1 text-sm text-gray-900 focus:outline-none focus:ring-offset-4 focus:ring-2 focus:ring-indigo-500 ${
                          product.countryOfOrigin
                            ? ""
                            : "ring-1 ring-red-300 ring-offset-4"
                        }`}
                        placeholder=""
                      />
                    </td>
                    <td className="px-3 py-2 w-1/12">
                      <input
                        type="text"
                        value={product.weight || ""}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "weight", index)
                        }
                        placeholder=""
                        className={`block w-full h-full border-none rounded-sm px-2 py-1 text-sm text-gray-900 focus:outline-none focus:ring-offset-4 focus:ring-2 focus:ring-indigo-500 ${
                          product.weight
                            ? ""
                            : "ring-1 ring-red-300 ring-offset-4"
                        }`}
                      />
                    </td>
                    <td className="px-3 py-2 w-1/12">
                      <input
                        type="text"
                        value={product.quantity || ""}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "quantity", index)
                        }
                        className={`block w-full h-full border-none rounded-sm px-2 py-1 text-sm text-gray-900 focus:outline-none focus:ring-offset-4 focus:ring-2 focus:ring-indigo-500 ${
                          product.quantity
                            ? ""
                            : "ring-1 ring-red-300 ring-offset-4"
                        }`}
                        placeholder=""
                      />
                    </td>
                    <td className="px-3 py-2 w-1/12">
                      <input
                        type="text"
                        value={product.price || ""}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "price", index)
                        }
                        className={`block w-full h-full border-none rounded-sm px-2 py-1 text-sm text-gray-900 focus:outline-none focus:ring-offset-4 focus:ring-2 focus:ring-indigo-500 ${
                          product.price
                            ? ""
                            : "ring-1 ring-red-300 ring-offset-4"
                        }`}
                        placeholder=""
                      />
                    </td>
                    <td className="px-3 py-2 w-1/12 text-sm text-gray-900 bg-gray-100 cursor-not-allowed">
                      {(product.price * product.quantity).toFixed(2)}
                    </td>
                    <td className="px-3 py-2 w-1/12 text-sm text-gray-900 bg-gray-100 cursor-not-allowed">
                      {(product.weight * product.quantity).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function EditDocument() {
  const { documentId } = useParams();
  const navigate = useNavigate();
  let defaultDate = today(getLocalTimeZone());
  const [value, setValue] = React.useState(defaultDate);
  const [currency, setCurrency] = useState();
  const [selectedDate, setSelectedDate] = React.useState(defaultDate);
  const [selectedKeys, setSelectedKeys] = useState(["1"]);

  const [senderAddress, setSenderAddress] = useState({});
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [invoiceAddress, setInvoiceAddress] = useState({});
  const [doc, setDoc] = useState({
    products: [],
  });
  const [createOrderOpen, setCreateOrderOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { addresses, fetchAddresses } = useAddressBook();
  const { loading, document, getDocument, updateDocument } = useDocuments();

  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchAddresses();
    getDocument(documentId);
  }, []);

  useEffect(() => {
    if (currency) {
      setDoc((prevDoc) => ({
        ...prevDoc,
        currency: currency,
      }));
    }
  }, [currency]);

  useEffect(() => {
    if (selectedDate) {
      setDoc((prevDoc) => ({
        ...prevDoc,
        documentDate: selectedDate,
      }));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (document && document.document) {
      setDoc(document.document);

      if (document.document.documentDate) {
        // Extrahera endast datumdelen (YYYY-MM-DD)
        const isoDate = document.document.documentDate.split("T")[0];
        const parsedDate = parseDate(isoDate); // Konvertera till CalendarDate
        setSelectedDate(parsedDate);
        setValue(parsedDate); // Sätt rätt format för DatePicker
      }
    }

    if (document && document.document?.currency) {
      setCurrency(document.document.currency);
    } else {
      setCurrency("SEK");
    }

    if (document && document.document?.shippingAddress) {
      setDeliveryAddress(document.document.shippingAddress);
      setSelectedKeys([]);
    }
  }, [document]);

  useEffect(() => {
    if (addresses?.length > 0 && document && document.document) {
      const documentSender = document.document.sender;

      if (documentSender && isAddressComplete(documentSender)) {
        setSenderAddress(documentSender);
      } else {
        const defaultAddress = addresses.find(
          (address) => address.defaultConsignor
        );
        if (defaultAddress) {
          setSenderAddress(defaultAddress);
          setSelectedKeys(["2"]);
        }
      }
    }
  }, [addresses, document]);

  useEffect(() => {
    if (isAddressComplete(deliveryAddress)) {
      setInvoiceAddress(deliveryAddress);
    } else {
      setInvoiceAddress({});
    }
  }, [deliveryAddress]);

  const isAddressComplete = (address) => {
    if (!address) return false;
    const requiredFields = [
      "company",
      "address1",
      "zip",
      "city",
      "countryCode",
    ];
    return requiredFields.every((field) => address[field]?.trim());
  };

  const isFormValid = () => {
    return (
      isAddressComplete(senderAddress) &&
      isAddressComplete(deliveryAddress) &&
      isAddressComplete(invoiceAddress)
    );
  };

  useEffect(() => {
    if (isFormValid()) {
      setFormData({
        sender: senderAddress,
        delivery: deliveryAddress,
        invoice: invoiceAddress,
        date: selectedDate,
      });
    }
  }, [senderAddress, deliveryAddress, invoiceAddress, selectedDate]);

  const handleNewRow = () => {
    const newProduct = {
      sku: "",
      title: "",
      hsCode: "",
      countryOfOrigin: "",
      weight: "",
      quantity: "",
      price: "",
    };

    setDoc((prevDoc) => ({
      ...prevDoc,
      products: [...(prevDoc.products || []), newProduct],
    }));
  };

  const handleUpdateDocument = () => {
    const formData = {
      document: {
        ...doc,
        documentDate: selectedDate.toString(),
        currency: currency,
        sender: senderAddress,
        shippingAddress: deliveryAddress,
        receiver: invoiceAddress,
      },
    };

    updateDocument(documentId, formData);
  };

  return (
    <>
      {/* Top Section */}
      <CreateOrder
        createOrderOpen={createOrderOpen}
        setCreateOrderOpen={setCreateOrderOpen}
      />
      <DocumentModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        doc={doc}
      />
      <div className="mx-auto max-w-5xl pb-5 sm:flex sm:items-center sm:justify-between">
        <div className="mt-3 flex items-center sm:mt-0 sm:flex-grow">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <ArrowLeftIcon
              className="-mr-1 h-5 w-10 text-gray-900"
              aria-hidden="true"
            />
          </button>
          <div className="ml-4">
            <h3 className="text-xl font-bold leading-7 text-gray-900">
              Redigerar {doc?.documentName}
            </h3>
            <div className="flex items-center gap-2">
              <p className="text-sm text-gray-500">
                Senast redigerad 2024-12-31
              </p>
            </div>
          </div>
        </div>
        {(doc?.status === "pending" || doc?.status === "edited") && (
          <div className="mt-3 flex items-center sm:ml-4 sm:mt-0">
            {loading && <Spinner className="mr-2" />}

            <button
              type="button"
              disabled={loading}
              onClick={handleUpdateDocument}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Spara ändringar
            </button>

            <button
              type="button"
              onClick={() => setModalOpen(true)}
              className="inline-flex items-center gap-x-1.5 ml-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Slutför
            </button>
            <DropdownMenu doc={doc} setCreateOrderOpen={setCreateOrderOpen} />
          </div>
        )}
      </div>

      {/* Main Content Section */}
      <div className="mx-auto max-w-5xl mt-4 flex flex-col items-start space-x-0 sm:flex-row sm:space-x-4">
        {/* Box 1 */}
        <div className="w-full sm:basis-3/6 mb-4 sm:mb-0">
          <div className="overflow-hidden rounded-lg border bg-white shadow">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Parter
              </h3>
              <p className="mt-1 text-sm/6 text-gray-600">
                Ange korrekt information om mottagare och avsändare.
              </p>
              <Accordion
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                disabledKeys={["3"]}
              >
                <AccordionItem
                  key="1"
                  aria-label="Avsändare"
                  title={
                    <div className="flex items-center">
                      <p className="text-base/7 font-semibold text-gray-900">
                        Avsändare
                      </p>
                      {isAddressComplete(senderAddress) && (
                        <CheckIcon className="ml-2 h-5 w-5 text-green-400" />
                      )}
                    </div>
                  }
                >
                  <AddressFields
                    prefix="sender"
                    addresses={addresses}
                    addressData={senderAddress}
                    setAddressData={setSenderAddress}
                  />
                </AccordionItem>
                <AccordionItem
                  key="2"
                  aria-label="Leveransadress"
                  title={
                    <div className="flex items-center">
                      <p className="text-base/7 font-semibold text-gray-900">
                        Leveransadress
                      </p>
                      {isAddressComplete(deliveryAddress) && (
                        <CheckIcon className="ml-2 h-5 w-5 text-green-400" />
                      )}
                    </div>
                  }
                  className="text-sm font-sans text-gray-500"
                >
                  <AddressFields
                    prefix="delivery"
                    addresses={addresses}
                    addressData={deliveryAddress}
                    setAddressData={setDeliveryAddress}
                  />
                </AccordionItem>
                <AccordionItem
                  key="3"
                  aria-label="Fakturaadress"
                  title={
                    <div className="flex items-center">
                      <p className="text-base/7 font-semibold text-gray-900">
                        Fakturaadress
                      </p>
                      {isAddressComplete(invoiceAddress) && (
                        <CheckIcon className="ml-2 h-5 w-5 text-green-400" />
                      )}
                    </div>
                  }
                  className="text-sm font-sans text-gray-500"
                >
                  <AddressFields
                    prefix="invoice"
                    addresses={addresses}
                    addressData={invoiceAddress}
                    setAddressData={setInvoiceAddress}
                  />
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>

        {/* Box 2 */}
        <div className="w-full sm:basis-3/6">
          <div className="overflow-hidden rounded-lg border bg-white shadow">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Detaljer
              </h3>
              <div className="grid grid-cols-2 mt-1 gap-x-2 text-sm/6 text-gray-600">
                <DatePicker
                  label="Fakturadatum"
                  className="max-w-full"
                  minValue={today(getLocalTimeZone())}
                  onChange={(newValue) => {
                    setValue(newValue); // Uppdatera CalendarDate-objektet
                    setSelectedDate(newValue); // Uppdatera valt datum
                  }}
                  value={value}
                />
                <Select
                  className="max-w-xs"
                  label="Valuta"
                  placeholder="Väj valuta"
                  selectedKeys={[currency]}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  {document?.currencies?.map((currency) => (
                    <SelectItem key={currency.code}>{currency.code}</SelectItem>
                  ))}
                </Select>
              </div>
              <div className="flex mt-8 gap-x-2 text-sm/6 text-gray-600">
                <div className="w-1/2">
                  <label
                    htmlFor="packageCount"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Antal paket
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="packageCount"
                      id="packageCount"
                      defaultValue={doc?.packageCount}
                      onChange={(e) => {
                        setDoc((prevDoc) => ({
                          ...prevDoc,
                          packageCount: e.target.value,
                        }));
                      }}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Referensnummer (valfritt)
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="documentNumber"
                      id="documentNumber"
                      onChange={(e) => {
                        setDoc((prevDoc) => ({
                          ...prevDoc,
                          documentNumber: e.target.value,
                        }));
                      }}
                      defaultValue={doc?.documentNumber}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl mt-4 flex flex-col items-start space-x-0 sm:flex-row sm:space-x-4">
        {/* Box 3 */}
        <div className="w-full sm:basis-6/6">
          <div className="overflow-hidden rounded-lg border bg-white shadow">
            <div className="flex justify-between px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Innehåll
              </h3>
              <button
                className="text-sm text-indigo-600 hover:text-indigo-900"
                onClick={handleNewRow}
              >
                Lägg till rad
              </button>
            </div>
            <InvoiceTable doc={doc} setDoc={setDoc} />
          </div>
        </div>
      </div>
    </>
  );
}
