import { useState, useEffect } from 'react';
import { useUpdate } from './updateContext';
import toast, { Toaster } from 'react-hot-toast';

function useDocuments() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [document, setDocument] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [addSuccess, setAddSuccess] = useState(null);

    const { setUpdateCount } = useUpdate();

    const createDocument = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);
        setAddSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_DOCUMENTS_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                // Försök läsa serverns felmeddelande från JSON
                let errorMessage = 'Ett fel uppstod';
                try {
                    const data = await response.json();
                    if (data?.message) {
                        errorMessage = data.message;
                    }
                } catch (err) {
                    // Om JSON-parse misslyckas, låt errorMessage förbli "Ett fel uppstod"
                }

                throw new Error(errorMessage);
            }

            setSuccess(true);
            setAddSuccess(true);
            setUpdateCount((prev) => prev + 1);
        } catch (error) {
            setError(error);
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    }

    const fetchDocuments = async (params = {}) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const url = new URL(process.env.REACT_APP_DOCUMENTS_URL);
            Object.keys(params).forEach(key => {
                if (params[key] !== undefined && params[key] !== null) {
                    url.searchParams.append(key, params[key]);  // Konverterar värden till query-parametrar
                }
            });

            const response = await fetch(url.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (!response.ok) {
                // Försök läsa serverns felmeddelande från JSON
                let errorMessage = 'Ett fel uppstod';
                try {
                    const data = await response.json();
                    if (data?.message) {
                        errorMessage = data.message;
                    }
                } catch (err) {
                    // Om JSON-parse misslyckas, låt errorMessage förbli "Ett fel uppstod"
                }

                throw new Error(errorMessage);
            }

            setDocument(data);
            setSuccess(true);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid hämtning av dokument');
        } finally {
            setLoading(false);
        }
    }

    const getDocument = async (_id, serverToken, uid) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const headers = {
                'Content-Type': 'application/json',
            };

            if (serverToken) {
                headers['X-Server-Token'] = serverToken;
            } else {
                headers['Authorization'] = `Bearer ${token}`;
            }

            const url = new URL(`${process.env.REACT_APP_DOCUMENTS_URL}/${_id}`);
            if (uid) {
                url.searchParams.append('uid', uid); // Lägg till uid som sökparameter
            }
            const response = await fetch(url, {
                method: 'GET',
                headers
            });

            const data = await response.json();
            if (!response.ok) {
                // Försök läsa serverns felmeddelande från JSON
                let errorMessage = 'Ett fel uppstod';
                try {
                    const data = await response.json();
                    if (data?.message) {
                        errorMessage = data.message;
                    }
                } catch (err) {
                    // Om JSON-parse misslyckas, låt errorMessage förbli "Ett fel uppstod"
                }

                throw new Error(errorMessage);
            }

            setDocument(data);
            setSuccess(true);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid hämtning av dokument');
        } finally {
            setLoading(false);
        }
    }

    const updateDocument = async (_id, formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_DOCUMENTS_URL}/${_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (!response.ok) {
                // Försök läsa serverns felmeddelande från JSON
                let errorMessage = 'Ett fel uppstod';
                try {
                    if (data?.message) {
                        errorMessage = data.message;
                    }
                } catch (err) {
                    // Om JSON-parse misslyckas, låt errorMessage förbli "Ett fel uppstod"
                }

                throw new Error(errorMessage);
            }

            setSuccess(true);
            setDocument(data);
            toast.success('Ändringarna sparades.');
            setUpdateCount((prev) => prev + 1);
        } catch (error) {
            setError(error);
            toast.error(error.message);
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    }

    const finishDocument = async (_id) => {
        setLoading(true);
        setError(null);
        setSuccess(null);
        setValidationErrors([]);


        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_DOCUMENTS_URL}/finish/${_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (!response.ok) {
                // Försök läsa serverns felmeddelande från JSON
                setValidationErrors(data?.errors);
                let errorMessage = 'Ett fel uppstod';
                try {
                    if (data?.message) {
                        errorMessage = data.message;
                    }
                } catch (err) {
                    // Om JSON-parse misslyckas, låt errorMessage förbli "Ett fel uppstod"
                }

                throw new Error(errorMessage);
            }

            setSuccess(true);
            setDocument(data);
            toast.success('Dokumentet är nu färdigställt.');
            setUpdateCount((prev) => prev + 1);
        } catch (error) {
            setError(error);
            toast.error(error.message);
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    }

    const deleteDocument = async (_id) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_DOCUMENTS_URL}/${_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (!response.ok) {
                // Försök läsa serverns felmeddelande från JSON
                let errorMessage = 'Ett fel uppstod';
                try {
                    if (data?.message) {
                        errorMessage = data.message;
                    }
                } catch (err) {
                    // Om JSON-parse misslyckas, låt errorMessage förbli "Ett fel uppstod"
                }

                throw new Error(errorMessage);
            }

            setSuccess(true);
            toast.success('Dokumentet raderades.');
            setUpdateCount((prev) => prev + 1);
        } catch (error) {
            setError(error);
            toast.error(error.message);
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    }

    return { createDocument, updateDocument, fetchDocuments, getDocument, finishDocument, deleteDocument, validationErrors, loading, error, success, document, addSuccess };

}

export default useDocuments;