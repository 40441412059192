import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetchPdfData from "../../contexts/pdfHook";

export default function CustomsInvoice() {
  const { orderId } = useParams(); // Hämta orderId från URL:en
  const { loading, error, pdfData, fetchPdfData } = useFetchPdfData();

  // Anropar fetchPdfData med orderId när komponenten renderas
  useEffect(() => {
    if (orderId) {
      fetchPdfData(orderId);
    }
  }, []);

  //   useEffect(() => {
  //     if (pdfData) {
  //       window.print();
  //     }
  //   }, [pdfData]);

  if (error) return <p>Ett fel inträffade: {error}</p>;
  if (!pdfData) return null;

  const customer = pdfData?.customer || {};
  const shippingAddress = pdfData?.shipping_address || {};
  const billingAddress = pdfData?.billing_address || {};
  const totalWeight = pdfData.order_items.reduce(
    (total, item) => total + item.quantity * (item.weight / 1000),
    0
  );

  return (
    <div className="p-4 bg-white">
      <style>
        {`
          @media print {
            body {
              -webkit-print-color-adjust: exact;
              font-family: Arial, sans-serif;
            }

            @page {
              size: A4;
              margin: 10mm; /* Minskade marginaler i x-led */
              counter-increment: page;
            }

            /* Gör så att innehållet inte bryts dåligt vid sidbrytningar */
            .page-break {
              page-break-after: always;
            }

            /* Sidnumrering i övre högra hörnet */
            header {
              position: fixed;
              top: 10mm;
              right: 10mm;
              text-align: right;
              font-size: 12px;
              counter-increment: page;
            }

            header::after {
              content: "Sida " counter(page);
            }

            /* Hanterar sidbrytningar för tabellen */
            table, tr, td {
              page-break-inside: avoid;
            }

            th, td {
              padding: 6px; /* Minskad padding för att spara utrymme */
              border: 1px solid #000;
            }

            th {
              background-color: #f1f1f1;
            }

            table {
              width: 100%;
              table-layout: fixed; /* Fixerar kolumnbredder */
            }

            /* Använd proportionella bredder för att anpassa kolumnstorleken */
            th:nth-child(1), td:nth-child(1) {
              width: 30%; /* Produktbeskrivning tar större andel */
            }

            th:nth-child(2), td:nth-child(2),
            th:nth-child(3), td:nth-child(3),
            th:nth-child(4), td:nth-child(4),
            th:nth-child(5), td:nth-child(5),
            th:nth-child(6), td:nth-child(6),
            th:nth-child(7), td:nth-child(7) {
              width: 10%; /* Mindre utrymme för övriga kolumner */
            }

          }
        `}
      </style>

      <div className="max-w-4xl mx-auto bg-white p-6">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">
            Proforma invoice - #{pdfData.id}
          </h1>
          <div className="text-right">
            <p className="text-sm">Order no.: #{pdfData.id}</p>
            <p className="text-sm">Order date: {pdfData.created_at}</p>
            <p className="text-sm">Customer number: #{pdfData.customer_id}</p>
          </div>
        </div>

        {/* Addresses */}
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div>
            <h2 className="font-semibold mb-2">Delivery address</h2>
            <p>
              {shippingAddress.company_name || ""}{" "}
              {shippingAddress && customer.organization_number}
            </p>
            <p>
              {shippingAddress.firstname} {shippingAddress.lastname}
            </p>
            <p>{shippingAddress.address}</p>
            <p>{shippingAddress.address2 || ""}</p>
            <p>
              {shippingAddress.postcode} {shippingAddress.city},{" "}
              {shippingAddress.country}
            </p>
            <p>{customer.phone}</p>
            <p>{customer.email}</p>
          </div>
          <div>
            <h2 className="font-semibold mb-2">Invoice address</h2>
            <p>
              {billingAddress.company_name || ""}{" "}
              {billingAddress.company_name && customer.organization_number}
            </p>
            <p>
              {billingAddress.firstname} {billingAddress.lastname}
            </p>
            <p>{billingAddress.address}</p>
            <p>
              {billingAddress.postcode} {billingAddress.city},{" "}
              {billingAddress.country}
            </p>
          </div>
          <div>
            <h2 className="font-semibold mb-2">Exporter</h2>
            <p>SCP Mopedbilsdelar AB</p>
            <p>Skällinge by 31</p>
            <p>43299 Skällinge, SE</p>
            <p>{customer.phone}</p>
            <p>kontakt@smallcarparts.se</p>
          </div>
        </div>

        {/* Delivery and Payment */}
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div>
            <h2 className="font-semibold mb-2">Delivery method</h2>
            <p>In-house</p>
          </div>
          <div>
            <h2 className="font-semibold mb-2">Payment method</h2>
            <p>Invoice</p>
          </div>
        </div>

        {/* Items Table */}
        <div className="border rounded-lg overflow-hidden mb-6">
          <table className="min-w-full bg-white border">
            <thead className="bg-gray-100 border-b">
              <tr>
                <th className="text-left py-2 px-4 border">
                  Product description
                </th>
                <th className="text-center py-2 px-4 border">HS</th>
                <th className="text-center py-2 px-4 border">Qty</th>
                <th className="text-center py-2 px-4 border">Total amount</th>
                <th className="text-right py-2 px-4 border">Total weight</th>
              </tr>
            </thead>
            <tbody>
              {pdfData.order_items
                .filter((item) => item.total > 0)
                .map((item) => (
                  <tr key={item.id} className="border-b">
                    <td className="py-2 px-4 border">{item.name}</td>
                    <td className="py-2 px-4 text-right border">
                      {item.hs_code}
                    </td>
                    <td className="py-2 px-4 text-right border">
                      {item.quantity}
                    </td>
                    <td className="py-2 px-4 text-right border">
                      {parseFloat(item.total / 100).toFixed(2)} SEK
                    </td>
                    <td className="py-2 px-4 text-right border">
                      {(item.quantity * (item.weight / 1000)).toFixed(2)} kg
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* Summary */}
        <div className="grid grid-cols-4 text-center border-t pt-4 rounded-xl bg-black text-white p-2">
          <div className="border-r mb-2">
            <p className="font-normal text-sm">Shipping cost</p>
            <p className="font-bold text-lg">
              {parseFloat(pdfData.shipping_method_price) / (100).toFixed(2)} SEK
            </p>
          </div>
          <div className="border-r mb-2">
            <p className="font-normal text-sm">Total net weight</p>
            <p className="font-bold text-lg">{totalWeight.toFixed(2)} kg</p>
          </div>
          <div className="border-r mb-2">
            <p className="font-normal text-sm">Total (excl VAT 25%)</p>
            <p className="font-bold text-lg">
              {parseFloat(
                ((pdfData.total - pdfData.shipping_method_price) / 100) * 0.8
              ).toFixed(2)}{" "}
              SEK
            </p>
          </div>
        </div>
        <p>*For B2B-export orders, Total incl VAT is not applicable</p>

        {/* Sidbrytning */}
        <div className="page-break"></div>
      </div>
    </div>
  );
}
