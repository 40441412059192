import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Select,
  SelectItem,
  Avatar,
  Tooltip,
  Spinner,
} from "@nextui-org/react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import useFetchShipments from "../../contexts/shipmentHook";
import useDocuments from "../../contexts/documentHook";

export default function NewCustomsInvoice({
  courierData,
  isLoading,
  tags,
  onDocumentCreated,
}) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [shipmentIds, setShipmentIds] = useState([]);
  const apiLoading = true;

  const { fetchShipmentsV2, loading, shipments } = useFetchShipments();
  const {
    createDocument,
    error,
    loading: docLoading,
    success,
  } = useDocuments();

  const handleSelectionChange = (value) => {
    setSelectedCourier(value);
  };

  const handleDocumentSelectionChange = (value) => {
    setSelectedDocument(value);
  };

  const handleServiceSelectionChange = (value) => {
    setSelectedService(value);
  };

  const handleTagSelectionChange = (value) => {
    setSelectedTag(value);
  };

  const allStatesFilled =
    selectedCourier && selectedDocument && selectedService && selectedTag;

  const activeServices =
    courierData?.find((courier) => courier._id === selectedCourier)
      ?.activeServices || [];

  const documents =
    courierData?.find((courier) => courier._id === selectedCourier)
      ?.documents || [];

  useEffect(() => {
    console.log(error);
  }, [error]);

  useEffect(() => {
    if (allStatesFilled) {
      setShipmentIds([]);
      fetchShipmentsV2({
        productCode: selectedService,
        tags: [selectedTag],
      });
    }
  }, [allStatesFilled, selectedService, selectedTag, selectedDocument]);

  useEffect(() => {
    if (shipments && shipments.shipments && shipments.shipments.length > 0) {
      setShipmentIds(shipments?.shipments.map((shipment) => shipment._id));
    }
  }, [shipments]);

  useEffect(() => {
    if (success === true) {
      setSelectedCourier(null);
      setSelectedDocument(null);
      setSelectedService(null);
      setSelectedTag(null);
      setShipmentIds([]);
      onClose();
    }
  }, [success]);

  const handleSubmit = async () => {
    try {
      const formData = {
        shipmentIds: shipmentIds,
        documentType: selectedDocument,
      };
      await createDocument(formData);
      onDocumentCreated();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button
        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={onOpen}
      >
        Nytt samlingsdokument
      </button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Skapa samlingsdokument
              </ModalHeader>
              <ModalBody>
                <Select
                  className="max-w-full"
                  label="Transportör"
                  placeholder="Välj transportör"
                  onChange={(e) => handleSelectionChange(e.target.value)}
                  isDisabled={isLoading}
                >
                  {courierData.map((courier) => (
                    <SelectItem
                      key={courier._id}
                      value={courier._id}
                      startContent={
                        <Avatar
                          alt={courier.courierName}
                          className="w-6 h-6"
                          src={courier.imageUrl}
                        />
                      }
                    >
                      {courier.courierName}
                    </SelectItem>
                  ))}
                </Select>
                {selectedCourier && (
                  <Select
                    className="max-w-full"
                    label="Dokument"
                    placeholder="Välj typ av dokument"
                    onChange={(e) =>
                      handleDocumentSelectionChange(e.target.value)
                    }
                    isDisabled={isLoading}
                  >
                    {documents.map((document) => (
                      <SelectItem key={document.id} value={document.id}>
                        {document.title}
                      </SelectItem>
                    ))}
                  </Select>
                )}
                {selectedCourier && selectedDocument && (
                  <div className="grid grid-cols-2 gap-x-3">
                    <Select
                      className="max-w-full"
                      label="Tjänst"
                      placeholder="Välj tjänst"
                      onChange={(e) =>
                        handleServiceSelectionChange(e.target.value)
                      }
                      isDisabled={isLoading}
                    >
                      {activeServices?.map((service) => (
                        <SelectItem
                          key={service.productCode}
                          value={service.productCode}
                        >
                          {service.serviceName}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      className="max-w-full"
                      label="Urval (tagg)"
                      placeholder="Välj urval av beställningar"
                      onChange={(e) => handleTagSelectionChange(e.target.value)}
                      isDisabled={isLoading}
                    >
                      {tags.map((tag) => (
                        <SelectItem
                          key={tag._id}
                          value={tag._id}
                          startContent={
                            <div
                              className="w-3 h-3 rounded-full mr-2"
                              style={{ backgroundColor: tag.color }}
                            ></div>
                          }
                        >
                          {tag.name}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                )}
                {allStatesFilled && (
                  <div className="flex justify-between items-center overflow-hidden rounded-lg px-4 py-2 shadow sm:p-6">
                    <Tooltip content="Paket som matchar dina val">
                      <p className="text-sm font-medium text-gray-500">
                        Matchande frakter
                      </p>
                    </Tooltip>
                    <div className="h-10 flex items-center justify-center">
                      {loading ? (
                        <Spinner />
                      ) : (
                        <p className="text-3xl font-semibold tracking-tight text-gray-900">
                          {shipments?.shipments.length}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {error && (
                  <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">
                          Det gick inte att skapa dokumentet.{" "}
                          <Link
                            to="/dash/faq"
                            className="text-blue-500 hover:text-blue-700"
                          >
                            Varför?
                          </Link>
                        </h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>{error.message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Stäng
                </Button>
                <Button
                  color="primary"
                  onPress={handleSubmit}
                  isLoading={docLoading}
                  isDisabled={docLoading || loading || !shipmentIds.length > 0}
                >
                  {docLoading ? "Förbereder dokument..." : "Skapa dokument"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
