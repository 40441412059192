import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import useDocuments from "../../contexts/documentHook";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

export default function DocumentModal({ modalOpen, setModalOpen, doc }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { finishDocument, loading, error, success, validationErrors } =
    useDocuments();

  const navigate = useNavigate();

  useEffect(() => {
    if (modalOpen == true) {
      onOpen();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (isOpen == false) {
      setModalOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (success) {
      onClose();
      navigate("/dash/customs");
    }
  }, [success]);

  const handleSubit = async () => {
    if (!doc) {
      return;
    }
    await finishDocument(doc._id);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Slutför dokument {doc?.documentNumber}
              </ModalHeader>
              <ModalBody>
                {!error && (
                  <p>
                    Innan dokumentet konverteras till en PDF så valideras
                    uppgifterna. Tänk på att noggrant granska uppgifterna så att
                    de stämmer. Du kan inte längre redigera dokumentet efter det
                    här steget. Vill du fortsätta?
                  </p>
                )}
                {((validationErrors && validationErrors.length > 0) ||
                  error) && (
                  <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        {error && (
                          <h3 className="text-sm font-medium text-yellow-800">
                            Kunde inte slutföra dokumentet
                          </h3>
                        )}
                        {validationErrors.length > 0 && (
                          <>
                            <div className="mt-2 text-sm text-yellow-700">
                              {/* Första stycket: Förklaring till användaren */}
                              <p>
                                Valideringsfel hittades i dokumentet.
                                Dubbelkolla de inmatade uppgifterna.
                              </p>
                              {validationErrors.map((rejection) => (
                                <p className="mt-2 text-yellow-800 bg-yellow-100 p-2 rounded-md shadow-lg">
                                  {rejection.field}: {rejection.message}
                                </p>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Stäng
                </Button>
                <Button
                  color="primary"
                  onPress={handleSubit}
                  isLoading={loading}
                >
                  {validationErrors.length > 0 || error
                    ? "Testa igen"
                    : "Fortsätt"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
