import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { PhotoIcon, UserCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import { Spinner, Switch } from "@nextui-org/react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import usePackage from "../../contexts/packageHook.js";
import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

const statuses = {
  true: "text-green-700 bg-green-50 ring-green-600/20",
  false: "text-gray-600 bg-gray-50 ring-gray-500/10",
  undefined: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Packages({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                      Paketdimensioner
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-gray-500">
                      När paketdimensioner är aktivt kan paketstorleken
                      specificeras vid fraktbokning.
                    </p>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <PickupAddresses />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default function PickupAddresses() {
  const [isAddmode, setIsAddmode] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const {
    setActivePackage,
    deletePackage,
    addPackage,
    fetchPackage,
    packageData,
    loading,
    success,
    active,
  } = usePackage();

  useEffect(() => {
    fetchPackage(); // Anropa funktionen för att hämta paket
  }, []);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  useEffect(() => {
    if (success) {
      setIsAddmode(false); // Stäng formuläret när success är true
    }
  }, [success]); // Lägg till success som beroende

  const handleSetAddmode = () => {
    setIsAddmode(true);
  };

  const handleRemovePackage = (_id) => {
    deletePackage(_id);
  };

  const handleActiveChange = (event) => {
    const newIsActiveValue = event.target.checked;
    setIsActive(newIsActiveValue);
    setActivePackage({ active: newIsActiveValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Förhindra att sidan laddas om

    const formData = {
      length: event.target.length.value,
      width: event.target.width.value,
      height: event.target.height.value,
      title: event.target.title.value,
    };

    if (!formData.title) {
      toast.error("Benämning måste vara ifylld.");
      return;
    }

    if (!formData.length || formData.length <= 0) {
      toast.error("Längd måste vara ett positivt tal.");
      return;
    }

    if (!formData.width || formData.width <= 0) {
      toast.error("Bredd måste vara ett positivt tal.");
      return;
    }

    if (!formData.height || formData.height <= 0) {
      toast.error("Höjd måste vara ett positivt tal.");
      return;
    }

    // Anropa funktionen för att lägga till en ny adress
    await addPackage(formData);
  };

  console.log("active", active);

  return (
    <>
      {isAddmode === false && (
        <>
          <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
            <Switch isSelected={isActive} onChange={handleActiveChange}>
              Aktivera
            </Switch>
            <button
              type="button"
              onClick={handleSetAddmode}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <PlusIcon className="h-5 w-5 mr-1" /> Ny paketmall
            </button>
            {loading && <Spinner className="ml-4" />}
          </div>
          <ul role="list" className="divide-y divide-gray-100">
            {loading === false &&
              packageData?.map((size) => (
                <li
                  key={size._id}
                  className="flex items-center justify-between gap-x-6 py-5"
                >
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {size.title}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      {size.title && (
                        <>
                          <p className="whitespace-nowrap">
                            Längd: {size.length} cm
                          </p>
                          <svg
                            viewBox="0 0 2 2"
                            className="h-0.5 w-0.5 fill-current"
                          >
                            <circle r={1} cx={1} cy={1} />
                          </svg>
                        </>
                      )}
                      <p className="whitespace-nowrap">
                        Bredd: {size.width} cm
                      </p>
                      <svg
                        viewBox="0 0 2 2"
                        className="h-0.5 w-0.5 fill-current"
                      >
                        <circle r={1} cx={1} cy={1} />
                      </svg>
                      <p className="whitespace-nowrap">
                        Höjd: {size.height} cm
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <Menu as="div" className="relative flex-none">
                      <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </MenuButton>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          <button
                            className="block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left data-[focus]:bg-gray-50"
                            onClick={() => handleRemovePackage(size._id)}
                          >
                            Ta bort
                            <span className="sr-only">, {size.title}</span>
                          </button>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </li>
              ))}
          </ul>
        </>
      )}

      {isAddmode === true && (
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Benämning
                  </label>
                  <div className="mt-2">
                    <input
                      id="title"
                      name="title"
                      placeholder="Liten wellpapplåda"
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="length"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Längd (cm)
                  </label>
                  <div className="mt-2">
                    <input
                      id="length"
                      name="length"
                      defaultValue="0"
                      type="number"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="width"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Bredd (cm)
                  </label>
                  <div className="mt-2">
                    <input
                      id="width"
                      name="width"
                      defaultValue="0"
                      type="number"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="height"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Höjd (cm)
                  </label>
                  <div className="mt-2">
                    <input
                      id="height"
                      name="height"
                      defaultValue="0"
                      type="number"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={() => setIsAddmode(false)}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Avbryt
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Lägg till
            </button>
          </div>
        </form>
      )}
    </>
  );
}

export { Packages };
