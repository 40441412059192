import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { PhotoIcon, UserCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import { Spinner, Checkbox } from "@nextui-org/react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useUserProfileUpdate from "../../contexts/profileHook.js";
import toast, { Toaster } from "react-hot-toast";
import { useState, useEffect } from "react";
import Order from "./order.jsx";
import useTags from "../../contexts/tagHook.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tags({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                      Taggar
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-gray-500">
                      Hantera ordertaggar för att hålla ordning på dina
                      beställningar.
                    </p>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <OrderTags />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function OrderTags() {
  const [isAddmode, setIsAddmode] = useState(false);
  const [tagColor, setTagColor] = useState("#2563eb");
  const [tagTitle, setTagTitle] = useState("");
  const [auto, setAuto] = useState(false);
  const [autoShipment, setAutoShipment] = useState(false);
  const { loading, error, success, tags, fetchTags, addTag, deleteTag } =
    useTags();

  const handleSetAddmode = () => {
    setIsAddmode(true);
  };

  const handleAutoChange = (event) => {
    setAuto(event.target.checked);
  };

  const handleAutoShipmentChange = (event) => {
    setAutoShipment(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.title.value,
      color: event.target.color.value,
      auto: auto,
      autoShipment: autoShipment,
    };

    if (formData.name === "") {
      toast.error("Fyll i namn");
      return;
    }
    if (formData.color === "") {
      toast.error("Välj en färg");
      return;
    }

    setAuto(false);
    await addTag(formData);
  };

  const handleDeleteTag = async (tagId) => {
    await deleteTag(tagId);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    if (success) {
      setIsAddmode(false); // Stäng formuläret när success är true
    }
  }, [success]); // Lägg till success som beroende

  return (
    <>
      {isAddmode === false && (
        <>
          <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
            <button
              type="button"
              onClick={handleSetAddmode}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <PlusIcon className="h-5 w-5 mr-1" /> Ny tagg
            </button>
            {loading && <Spinner className="ml-4" />}
          </div>
          <ul role="list" className="divide-y divide-gray-100">
            {loading === false &&
              tags?.map((tag) => (
                <li
                  key={tag._id}
                  className="flex items-center justify-between gap-x-6 py-5"
                >
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {tag.name}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <div
                        className="w-4 h-4 rounded border border-gray-300"
                        style={{ backgroundColor: tag.color }}
                        title={tag.color} // Tooltip för att visa färgkoden
                      ></div>
                      <p className="whitespace-nowrap">
                        {tag.auto &&
                          tag.autoShipment &&
                          "Auto: Beställning och frakt"}
                        {tag.auto && !tag.autoShipment && "Auto: Beställning"}
                        {!tag.auto && tag.autoShipment && "Auto: Frakt"}
                        {!tag.auto && !tag.autoShipment && "Normal"}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <Menu as="div" className="relative flex-none">
                      <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </MenuButton>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          <button
                            className="block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left data-[focus]:bg-gray-50"
                            onClick={() => handleDeleteTag(tag._id)}
                          >
                            Ta bort
                            <span className="sr-only">, {tag.name}</span>
                          </button>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </li>
              ))}
          </ul>
        </>
      )}
      {isAddmode === true && (
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Benämning
                  </label>
                  <div className="mt-2">
                    <input
                      id="title"
                      name="title"
                      placeholder="Skickas på måndag"
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="color"
                    className="block text-sm font-medium mb-2 text-gray-900"
                  >
                    Välj en färg
                  </label>
                  <input
                    type="color"
                    className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg 
                    disabled:opacity-50 disabled:pointer-events-none 
                    dark:bg-neutral-900 dark:border-neutral-700"
                    id="color"
                    value={tagColor}
                    onChange={(event) => setTagColor(event.target.value)}
                    title="Välj färg"
                  />
                </div>
                <div className="col col-span-full">
                  <label
                    htmlFor="checkbox"
                    className="block text-sm font-medium mb-2 text-gray-900"
                  >
                    <span className="flex items-center">Regler (valfritt)</span>
                  </label>
                  <div className="mt-2 space-y-0">
                    <Checkbox
                      defaultChecked={auto}
                      size="md"
                      onChange={handleAutoChange}
                    >
                      Lägg till på beställningar när de skickas
                    </Checkbox>
                    <Checkbox
                      defaultChecked={autoShipment}
                      size="md"
                      onChange={handleAutoShipmentChange}
                    >
                      Lägg till på nya frakter
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={() => setIsAddmode(false)}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Avbryt
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Lägg till tagg
            </button>
          </div>
        </form>
      )}
    </>
  );
}
