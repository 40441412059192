import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { PhotoIcon, UserCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import { Spinner, Checkbox } from "@nextui-org/react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { useAddressBook } from "../../contexts/addressHook.js";
import { ChevronDownIcon } from "@heroicons/react/16/solid";

const statuses = {
  true: "text-green-700 bg-green-50 ring-green-600/20",
  false: "text-gray-600 bg-gray-50 ring-gray-500/10",
  undefined: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddressFields({ address, onSave, onCancel }) {
  const [formData, setFormData] = useState(address || {});

  useEffect(() => {
    if (!formData.consignor) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        defaultConsignor: false,
      }));
    }
  }, [formData.consignor]);

  useEffect(() => {
    if (!formData.countryCode) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        countryCode: "SE",
      }));
    }
  }, [formData.countryCode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };

  const handleSubmit = () => {
    onSave(formData, formData._id);
  };

  return (
    <div>
      <div className="-mt-4 grid grid-cols-1 gap-x-2 sm:grid-cols-6">
        <div className="sm:col-span-6 mt-2">
          <input
            id={`company`}
            name={`company`}
            type="text"
            placeholder="Företag"
            value={formData.company || ""}
            onChange={handleChange}
            autoComplete="email"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-6 mt-2">
          <input
            id={`vatNumber`}
            name={`vatNumber`}
            type="text"
            placeholder="Momsregistreringsnummer (VAT)"
            value={formData.vatNumber || ""}
            onChange={handleChange}
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-3 mt-2">
          <input
            id={`firstName`}
            name={`firstName`}
            type="text"
            placeholder="Förnamn"
            value={formData.firstName || ""}
            onChange={handleChange}
            autoComplete="given-name"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-3 mt-2">
          <input
            id={`lastName`}
            name={`lastName`}
            type="text"
            placeholder="Efternamn"
            value={formData.lastName || ""}
            onChange={handleChange}
            autoComplete="family-name"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-6 mt-2">
          <input
            id={`address1`}
            name={`address1`}
            type="text"
            placeholder="Adress 1"
            value={formData.address1 || ""}
            onChange={handleChange}
            autoComplete="address-line1"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-6 mt-2">
          <input
            id={`address2`}
            name={`address2`}
            type="text"
            value={formData.address2 || ""}
            onChange={handleChange}
            placeholder="Adress 2 (valfritt)"
            autoComplete="address-line2"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-2 mt-2">
          <input
            id={`zip`}
            name={`zip`}
            type="text"
            value={formData.zip || ""}
            onChange={handleChange}
            placeholder="Postnr"
            autoComplete="postal-code"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-4 mt-2">
          <input
            id={`city`}
            name={`city`}
            type="text"
            value={formData.city || ""}
            onChange={handleChange}
            placeholder="Stad"
            autoComplete="address-level2"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-3 mt-2">
          <input
            id={`state`}
            name={`state`}
            type="text"
            value={formData.state || ""}
            onChange={handleChange}
            placeholder="Stat/provins"
            autoComplete="state"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-3 mt-2">
          <select
            id="countryCode"
            name="countryCode"
            value={formData.countryCode || "SE"}
            onChange={
              (e) => setFormData({ ...formData, countryCode: e.target.value }) // Uppdatera formData vid ändring
            }
            autoComplete="country-name"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          >
            <option value="SE">Sverige</option>
            <option value="NO">Norge</option>
            <option value="FI">Finland</option>
          </select>
        </div>

        <div className="sm:col-span-4 mt-2">
          <input
            id={`email`}
            name={`email`}
            type="email"
            value={formData.email || ""}
            onChange={handleChange}
            placeholder="E-postadress"
            autoComplete="email"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-2 mt-2">
          <input
            id={`phone`}
            name={`phone`}
            type="tel"
            value={formData.phone || ""}
            onChange={handleChange}
            placeholder="Telefon"
            autoComplete="tel"
            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
          />
        </div>

        <div className="sm:col-span-2 mt-4">
          <label
            htmlFor="incoterm"
            className="block text-sm/6 font-medium text-gray-900"
          >
            Förvald incoterm
          </label>
          <div className="mt-1">
            <select
              id="defaultIncoterm"
              name="defaultIncoterm"
              defaultValue={formData.defaultIncoterm || "null"}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  defaultIncoterm:
                    e.target.value === "null" ? null : e.target.value, // Hantera "null" korrekt
                })
              }
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
            >
              <option value="null">Ingen</option>
              <option value="DAP">DAP</option>
              <option value="FOB">FOB</option>
            </select>
          </div>
        </div>
        <div className="sm:col-span-2 mt-4">
          <label
            htmlFor="consignor"
            className="block text-sm/6 font-medium text-gray-900"
          >
            Typ
          </label>
          <div className="mt-1">
            <select
              id="consignor"
              name="consignor"
              defaultValue={formData.consignor || false}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  consignor: JSON.parse(e.target.value),
                })
              }
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
            >
              <option value={false}>Mottagare</option>
              <option value={true}>Avsändare</option>
            </select>
          </div>
        </div>
        {formData.consignor && formData.consignor === true && (
          <div className="sm:col-span-full mt-4">
            <label
              htmlFor="default"
              className="block text-sm/6 font-medium text-gray-900"
            >
              Sätt som förvald avsändare
            </label>
            <div className="mt-1">
              <Checkbox
                defaultSelected={formData.defaultConsignor}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    defaultConsignor: e.target.checked,
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          onClick={onCancel}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Avbryt
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {address ? "Spara ändringar" : "Lägg till adress"}
        </button>
      </div>
    </div>
  );
}

function AddressBook({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                      Adressbok
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-gray-500">
                      Lägg till och redigera sparade avsändare och mottagare.
                    </p>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <Addresses />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function Addresses() {
  const [mode, setMode] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const {
    addresses,
    loading,
    error,
    fetchAddresses,
    addAddress,
    updateAddress,
    deleteAddress,
  } = useAddressBook();

  useEffect(() => {
    fetchAddresses();
  }, []);

  const handleSetMode = () => {
    setMode(true);
  };

  const handleEditAddress = (address) => {
    setSelectedAddress(address);
    setMode("edit");
  };

  const handleSaveAddress = (updatedAddress, id) => {
    if (mode === "edit") {
      updateAddress(updatedAddress, id);
    } else {
      addAddress(updatedAddress);
    }
    setMode(false);
    setSelectedAddress(null);
  };

  const handleCancelEdit = () => {
    setMode(false);
    setSelectedAddress(null);
  };

  const handleRemoveAddress = (_id) => {
    deleteAddress(_id);
  };

  return (
    <>
      {mode === false && (
        <>
          <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-start">
            <button
              type="button"
              onClick={handleSetMode}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <PlusIcon className="h-5 w-5 mr-1" /> Lägg till adress
            </button>
            {loading && <Spinner className="ml-4" />}
          </div>
          <ul role="list" className="divide-y divide-gray-100">
            {loading === false &&
              addresses?.map((address) => (
                <li
                  key={address._id}
                  className="flex items-center justify-between gap-x-6 py-5"
                >
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {address.company}
                      </p>
                      {address.defaultConsignor && (
                        <p
                          className={classNames(
                            statuses[address.defaultConsignor],
                            "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                          )}
                        >
                          {address.defaultConsignor ? "Förvald avsändare" : ""}
                        </p>
                      )}
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      {address.address1 && (
                        <>
                          <p className="whitespace-nowrap">
                            {address.address1}
                          </p>
                          <svg
                            viewBox="0 0 2 2"
                            className="h-0.5 w-0.5 fill-current"
                          >
                            <circle r={1} cx={1} cy={1} />
                          </svg>
                        </>
                      )}
                      <p className="whitespace-nowrap">
                        {address.zip} {address.city}
                      </p>
                      <svg
                        viewBox="0 0 2 2"
                        className="h-0.5 w-0.5 fill-current"
                      >
                        <circle r={1} cx={1} cy={1} />
                      </svg>
                      <p className="truncate">{address.countryCode}</p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <Menu as="div" className="relative flex-none">
                      <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </MenuButton>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        {address.default !== true && (
                          <MenuItem>
                            <button
                              className="block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left data-[focus]:bg-gray-50"
                              onClick={() => handleEditAddress(address)}
                            >
                              Redigera
                              <span className="sr-only">
                                , {address.street}
                              </span>
                            </button>
                          </MenuItem>
                        )}
                        <MenuItem>
                          <button
                            className="block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left data-[focus]:bg-gray-50"
                            onClick={() => handleRemoveAddress(address._id)}
                          >
                            Ta bort
                            <span className="sr-only">, {address.street}</span>
                          </button>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </li>
              ))}
          </ul>
        </>
      )}
      {mode === true && (
        <AddressFields onSave={handleSaveAddress} onCancel={handleCancelEdit} />
      )}
      {mode === "edit" && (
        <AddressFields
          address={selectedAddress}
          onSave={handleSaveAddress}
          onCancel={handleCancelEdit}
        />
      )}
    </>
  );
}

export { AddressBook };
