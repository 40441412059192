import NewInvoice from "../pdf/newInvoiceModal";
import NewCustomsInvoice from "../components/newCustomsInvoiceModal";
import { useCourierData } from "../../contexts/courierHook";
import useTags from "../../contexts/tagHook";
import { useEffect, useState } from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/20/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { SkeletonRow } from "./tableSkeleton";
import useDocuments from "../../contexts/documentHook";
import courierConfig from "../../configuration/courierConfig";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function StatusChip({ status }) {
  let bgColor = "bg-indigo-50";
  let textColor = "text-indigo-700";
  let borderColor = "ring-indigo-700/10";
  let statusMessage = "Öppen";

  if (status === "completed") {
    bgColor = "bg-gray-50";
    textColor = "text-gray-600";
    borderColor = "ring-gray-500/10";
    statusMessage = "Slutförd";
  } else if (status === "canceled") {
    bgColor = "bg-red-50";
    textColor = "text-red-700";
    borderColor = "ring-red-600/20";
    statusMessage = "Avbokad";
  } else if (status === "confirmed") {
    bgColor = "bg-green-50";
    textColor = "text-green-700";
    borderColor = "ring-green-600/20";
    statusMessage = "Bekräftad";
  }

  return (
    <span
      className={`inline-flex items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${borderColor}`}
    >
      {statusMessage}
    </span>
  );
}

function DropdownMenu({ deleteDocument, id }) {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left ml-3">
        <div className="flex flex-col-2 gap-x-1">
          <MenuButton className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon aria-hidden="true" className="h-6 w-6" />
          </MenuButton>
        </div>

        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            <MenuItem>
              <button
                onClick={() => deleteDocument(id)}
                className="block w-full px-4 py-2 text-sm text-left text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              >
                Ta bort
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
    </>
  );
}

function Pagination({ currentPage, totalPages, onPageChange }) {
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <nav className="flex items-center justify-between border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${
            currentPage === 1
              ? "text-gray-300"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
          Föregående
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {/* Här kan du lägga till logik för att visa sidnummer */}
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => onPageChange(index + 1)}
            className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
              currentPage === index + 1
                ? "border-indigo-500 text-indigo-600"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium ${
            currentPage === totalPages
              ? "text-gray-300"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
        >
          Nästa
          <ArrowLongRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
}

export default function Customs() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { courierData, loading: isLoading } = useCourierData();
  const { tags, fetchTags } = useTags();
  const {
    document: documentArray,
    loading,
    fetchDocuments,
    deleteDocument,
  } = useDocuments();

  const documents = documentArray?.documents;

  useEffect(() => {
    fetchTags();
    fetchDocuments({
      direction: "desc",
    });
  }, []);

  useEffect(() => {
    if (documents) {
      setTotalPages(documentArray.totalPages);
    }
  }, [documents]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchDocuments({
      page: page,
      direction: "desc",
    });
  };

  const onDocumentCreated = () => {
    fetchDocuments(); // ladda om direkt
  };

  const navigate = useNavigate();
  const handleOpenDoc = (doc) => {
    if (doc.status === "completed") {
      window.open(`${doc.documentUrl}`, "_blank");
    } else {
      navigate(`/dash/customs/${doc._id}`);
    }
  };

  const handleDeleteDocument = async (id) => {
    await deleteDocument(id);
    fetchDocuments({
      page: currentPage,
      direction: "desc",
    });
  };

  return (
    <div className="mx-auto sm:mt-4 max-w-7xl px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        Tull & export
      </h2>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            Visa, skapa och hantera tulldeklarationer och exportdokument.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <NewInvoice />
        </div>
        <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-none">
          <NewCustomsInvoice
            courierData={courierData}
            isLoading={isLoading}
            tags={tags}
            onDocumentCreated={onDocumentCreated}
          />
        </div>
      </div>
      <div className="mt-2 mx-auto max-w-5xl">
        <div className="px-4 sm:px-6 lg:px-8 bg-white rounded-md sm:rounded-xl shadow">
          <div className="py-4 mt-8 flow-root">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        Skapad
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Transportör
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Namn
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                      >
                        <span className="sr-only">Hantera</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="5">
                          <SkeletonRow />
                        </td>
                      </tr>
                    ) : documents && documents.length > 0 ? (
                      documents.map((doc, index) => {
                        const courier = courierConfig[doc.courierId] || {};
                        return (
                          <tr key={doc._id}>
                            <td
                              className={classNames(
                                index !== documents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {new Intl.DateTimeFormat("sv-SE").format(
                                new Date(doc.createdAt)
                              )}
                            </td>
                            <td
                              className={classNames(
                                index !== documents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                              )}
                            >
                              <img
                                className="h-10 w-10 rounded-full"
                                src={courier.imageUrl}
                                alt={courier.name}
                              />
                            </td>
                            <td
                              className={classNames(
                                index !== documents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                              )}
                            >
                              <div>{doc.documentName}</div>
                            </td>
                            <td
                              className={classNames(
                                index !== documents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                              )}
                            >
                              <StatusChip status={doc.status} />
                            </td>
                            <td
                              className={classNames(
                                index !== documents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8"
                              )}
                            >
                              <div className="flex justify-end">
                                <button
                                  type="button"
                                  onClick={() => handleOpenDoc(doc)}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  {doc.status === "completed"
                                    ? "Öppna"
                                    : "Redigera"}
                                </button>
                                <DropdownMenu
                                  deleteDocument={handleDeleteDocument}
                                  id={doc._id}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-5">
                          Inga dokument hittades.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
