import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import { Toaster } from 'react-hot-toast';
import AuthProvider, { useAuth } from './contexts/AuthContext'
import { UpdateProvider } from './contexts/updateContext';
import { NextUIProvider } from "@nextui-org/react";
import PrivateRoute from "./router/route"


import Dash from './scenes/global/Dash';
import Index from './scenes/dashboard/index';
import Login from './scenes/components/login';
import Forgot from './scenes/components/forgot';
import NewPassword from './scenes/components/newPassword'
import Profile from './scenes/components/profile'
import UserProfile from './scenes/components/userProfile';
import Faq from './scenes/dashboard/faq';
import Settings from './scenes/global/settings';
import Couriers from './scenes/components/couriers';
import Integrations from './scenes/components/integrations';
import Orders from './scenes/global/orders';
import Order from './scenes/global/order';
import Pickups from './scenes/global/pickups';
import GeneralSettings from './scenes/global/general';
import Customs from './scenes/global/customs';
import CustomsInvoice from './scenes/pdf/customsInvoice';
import CommercialInvoice from './scenes/pdf/commercialInvoice';
import EditDocument from './scenes/components/editDocument';

import './App.css';



const App = () => {

  return (
    <AuthProvider>
      <NextUIProvider>
        <ThemeProvider>
          <UpdateProvider>
            <Toaster
              position="top-right"
              reverseOrder={true}
              containerStyle={{
                top: 70
              }}
            />
            <Routes>
              <Route path="/customs/invoice/:orderId" element={<CustomsInvoice />} />
              <Route path="/customs/commercialinvoice/:documentId" element={<CommercialInvoice />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/new-password/" element={<NewPassword />} />
              <Route path="/" element={<PrivateRoute />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dash" element={<Dash />}>
                  <Route index element={<Index />} />
                  <Route path="orders" element={<Outlet />}>
                    <Route index element={<Orders />} />
                    <Route path=":orderId" element={<Order />} />
                  </Route>
                  <Route path="customs" element={<Outlet />}>
                    <Route index element={<Customs />} />
                    <Route path=":documentId" element={<EditDocument />} />
                  </Route>
                  <Route path="pickups" element={<Pickups />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="user-profile" element={<UserProfile />} />
                  <Route path="faq" element={<Faq />} />
                  <Route path="settings" element={<Settings />}>
                    <Route path="general" element={<Outlet />}>
                      <Route index element={<GeneralSettings />} />
                    </Route>
                    <Route path="couriers" element={<Couriers />} />
                    <Route path="integrations" element={<Outlet />}>
                      <Route index element={<Integrations />} />
                      <Route path=":callbackId" element={<Integrations />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
              {/* Redirect användare baserat på autentiseringsstatus */}
              {/* ...andra routes */}
            </Routes>
          </UpdateProvider>
        </ThemeProvider>
      </NextUIProvider>
    </AuthProvider>

  )
}

const MainComponent = () => {
  const { toggleTheme } = useTheme();

  return (
    <div>
      <button onClick={toggleTheme}> Light/dark mode</button>
      <div class="bg-white dark:bg-gray-800">
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">
            Email
          </label>
          <div className="mt-2">
            <input
              type="email"
              name="email"
              id="email"
              className="block w-full rounded-md border-0 py-1.5 dark:bg-gray-800 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="you@example.com"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default App