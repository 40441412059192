import React, { useState, useEffect } from "react";
import {
  Modal,
  Tooltip,
  Select,
  SelectItem,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Avatar,
} from "@nextui-org/react";
import { useCourierUpdate } from "../../contexts/courierHook";
import { useUpdate } from "../../contexts/updateContext";
import useTags from "../../contexts/tagHook.js";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import courierConfig from "../../configuration/courierConfig";

function formatDateTime(dateString) {
  if (!dateString) return "Laddar..."; // Hanterar null eller undefined

  try {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return new Intl.DateTimeFormat("sv-SE", options)
      .format(date)
      .replace(/\//g, "-");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Datumfel"; // Returnerar en fallback-sträng vid fel
  }
}

export default function ManageShipment({ orderData, shipment, tags }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { updateTags, fetchTags, loading } = useTags();
  const [selectedTags, setSelectedTags] = useState([]);
  const [changed, setChanged] = useState(false);
  const config = courierConfig[shipment.courierIdentifier];
  const currentTags = shipment.tags;

  const handleSelectedTags = (value) => {
    const tagIds = value
      ? value.split(",").filter((id) => id.trim() !== "") // Dela upp och filtrera bort tomma värden
      : []; // Om value är tomt, sätt en tom array

    setSelectedTags(tagIds);
    setChanged(true);
  };

  const handleSubmit = async () => {
    try {
      if (changed === false) {
        return onClose();
      }
      const currentTagIds = currentTags.map((tag) => tag._id.toString());
      const selectedTagIds = selectedTags.map((tag) => tag.toString());

      const addedTags = selectedTagIds.filter(
        (tagId) => !currentTagIds.includes(tagId)
      );

      const removedTags = currentTagIds.filter(
        (tagId) => !selectedTagIds.includes(tagId)
      );

      console.log("Added Tags:", addedTags);
      console.log("Removed Tags:", removedTags);

      // Uppdatera taggar
      const shipmentIds = [shipment._id];
      const orderIds = [];
      await updateTags(orderIds, shipmentIds, addedTags, removedTags);
      setChanged(false);
      onClose();
    } catch (error) {
      console.error("Error updating tags:", error);
    }
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="text-indigo-600 hover:text-indigo-900"
      >
        Hantera
      </button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Hantera leverans
              </ModalHeader>
              <ModalBody>
                <div>
                  <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                      Fraktinformation
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                      Detaljer och dokument relaterade till frakten.
                    </p>
                  </div>
                  <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Transportör
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                          <img
                            src={config.imageUrl} // Exempel URL till DHL logotyp
                            alt="Carrier logo"
                            className="w-6 h-6 rounded-full mr-2"
                          />
                          {shipment.courierName}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Frakttjänst
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {shipment.serviceName} ({shipment.productCode})
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Kollinummer
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-indigo-600 sm:col-span-2 sm:mt-0">
                          <Tooltip
                            content="Klicka för att spåra"
                            placement="bottom"
                            closeDelay="100"
                          >
                            <a
                              href={shipment.trackingUrl || ""}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {shipment.shipmentId}
                            </a>
                          </Tooltip>
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Skapad
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {formatDateTime(shipment.createdAt)}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Typ
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {shipment.returnShipment === true && "Retur"}
                          {shipment.returnShipment === false && "Utleverans"}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Taggar
                        </dt>
                        <dd className="mt-1 flex items-center text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <Select
                            label="Välj taggar"
                            placeholder="Ingen"
                            className="max-w-full"
                            selectionMode="multiple"
                            onChange={(e) => handleSelectedTags(e.target.value)}
                            defaultSelectedKeys={shipment.tags.map(
                              (tag) => tag._id
                            )}
                          >
                            {tags?.map((tag) => (
                              <SelectItem
                                key={tag._id}
                                value={tag._id}
                                startContent={
                                  <div
                                    className="w-3 h-3 rounded-full mr-2"
                                    style={{ backgroundColor: tag.color }}
                                  ></div>
                                }
                              >
                                {tag.name}
                              </SelectItem>
                            ))}
                          </Select>
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Dokument
                        </dt>
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <ul
                            role="list"
                            className="divide-y divide-gray-100 rounded-md border border-gray-200"
                          >
                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon
                                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                  <span className="truncate font-medium">
                                    Fraktsedel (pdf)
                                  </span>
                                </div>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a
                                  href={shipment.path}
                                  className="font-medium text-indigo-600 hover:text-indigo-500"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Öppna
                                </a>
                              </div>
                            </li>
                          </ul>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onPress={handleSubmit}
                  isLoading={loading}
                >
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
