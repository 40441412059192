const courierConfig = {
  1: {
    name: 'DHL Freight',
    imageUrl: 'https://flowtrade.s3.eu-north-1.amazonaws.com/assets/logos/dhl-logo-500x500.png',
  },
  2: {
    name: 'Bring',
    imageUrl: 'https://flowtrade.s3.eu-north-1.amazonaws.com/assets/logos/bring-logo-500x500.png',
    documents: [
      {
        title: "Commercial Invoice",
        id: 1,
      }
    ]
  },
  3: {
    name: 'DB Schenker',
    imageUrl: 'https://flowtrade.s3.eu-north-1.amazonaws.com/assets/logos/dbschenker-logo-500x500.png'
  }
  // Add more as needed
};

export default courierConfig;