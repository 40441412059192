import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Select,
  SelectItem,
} from "@nextui-org/react";
import useTags from "../../contexts/tagHook.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ManageOrderTags({
  tagsOpen,
  setTagsOpen,
  orders,
  setAddedTags,
}) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [addTag, setAddTag] = useState([]);
  const [removeTag, setRemoveTag] = useState([]);
  const { loading, error, success, tags, fetchTags, updateTags } = useTags();

  const navigate = useNavigate();

  const handleNavigateClick = () => {
    navigate("/dash/settings/general?tagsOpen=true"); // Navigera internt till /tags utan att ladda om sidan
  };

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    if (tagsOpen == true) {
      onOpen();
    }
  }, [tagsOpen]);

  useEffect(() => {
    if (isOpen == false) {
      setTagsOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (success) {
      onClose();
      setAddedTags(true);
    }
  }, [success]);

  const handleAddTags = (value) => {
    const tagIds = value
      ? value.split(",").filter((id) => id.trim() !== "") // Dela upp och filtrera bort tomma värden
      : []; // Om value är tomt, sätt en tom array

    setAddTag(tagIds);
  };

  const handleRemoveTag = (value) => {
    const tagIds = value
      ? value.split(",").filter((id) => id.trim() !== "") // Dela upp och filtrera bort tomma värden
      : []; // Om value är tomt, sätt en tom array

    setRemoveTag(tagIds);
  };

  const handleSubmit = async () => {
    try {
      setAddedTags(false);
      const orderIds = orders.map((order) => order._id);
      const shipmentIds = [];
      await updateTags(orderIds, shipmentIds, addTag, removeTag);
    } catch (error) {
      console.log(error);
    }
  };

  const orderCount = orders?.length;

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Multiuppdatera taggar för {orderCount} beställning
                {orderCount > 1 ? "ar" : ""}
              </ModalHeader>
              <ModalBody>
                <div className="mb-2">
                  <Button
                    color="primary"
                    variant="bordered"
                    onClick={handleNavigateClick}
                  >
                    Hantera taggar
                  </Button>
                </div>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900">
                      Lägg till taggar
                    </span>
                  </div>
                </div>
                <Select
                  className="max-w-full"
                  label="Taggar"
                  placeholder="Inga taggar valda"
                  selectionMode="multiple"
                  onChange={(e) => handleAddTags(e.target.value)}
                >
                  {tags?.map((tag) => (
                    <SelectItem
                      key={tag._id}
                      value={tag._id}
                      startContent={
                        <div
                          className="w-3 h-3 rounded-full mr-2"
                          style={{ backgroundColor: tag.color }}
                        ></div>
                      }
                    >
                      {tag.name}
                    </SelectItem>
                  ))}
                </Select>
                <p className="text-small text-default-500">
                  Välj en eller flera taggar att lägga till för urvalet av
                  beställningar.
                </p>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900">
                      Ta bort taggar
                    </span>
                  </div>
                </div>
                <Select
                  className="max-w-full"
                  label="Taggar"
                  placeholder="Inga taggar valda"
                  selectionMode="multiple"
                  onChange={(e) => handleRemoveTag(e.target.value)}
                >
                  {tags?.map((tag) => (
                    <SelectItem
                      key={tag._id}
                      value={tag._id}
                      startContent={
                        <div
                          className="w-3 h-3 rounded-full mr-2"
                          style={{ backgroundColor: tag.color }}
                        ></div>
                      }
                    >
                      {tag.name}
                    </SelectItem>
                  ))}
                </Select>
                <p className="text-small text-default-500">
                  Ta bort taggar från urvalet av beställningar.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Stäng
                </Button>
                <Button
                  color="primary"
                  onPress={handleSubmit}
                  isLoading={loading}
                  isDisabled={loading}
                >
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
