import { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useUpdate } from './updateContext';

function useTags() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tags, setTags] = useState(null);
    const [success, setSuccess] = useState(null);
    const { setUpdateCount } = useUpdate();

    const fetchTags = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_TAGS_URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while fetching tags'
            }
            setTags(data.tags);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid hämtning av taggar');
        } finally {
            setLoading(false);
        }
    }

    const addTag = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_TAGS_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while adding tag'
            }
            setTags(data.tags);
            toast.success('Taggen skapades.');
            setSuccess(true);
        } catch (error) {
            setError(error.message);
            toast.error('Kunde inte skapa tagg');
        } finally {
            setLoading(false);
        }
    }

    const deleteTag = async (_id) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_TAGS_URL}/${_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while deleting tag'
            }
            setTags(data.tags);
            setSuccess(true);
            toast.success('Taggen togs bort');
        } catch (error) {
            setError(error.message);
            toast.error('Kunde inte ta bort tagg');
        } finally {
            setLoading(false);
        }
    }

    const updateTags = async (orderIds, shipmentIds, addTagIds, removeTagIds) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_UPDATE_TAGS_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ orderIds, shipmentIds, addTagIds, removeTagIds }),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while updating tags'
            }
            setSuccess(true);
            setUpdateCount(prevCount => prevCount + 1);
            toast.success('Taggar uppdaterades');
        } catch (error) {
            setError(error.message);
            toast.error('Kunde inte uppdatera taggar');
        } finally {
            setLoading(false);
        }
    }
    return { loading, error, success, tags, fetchTags, addTag, deleteTag, updateTags };
}

export default useTags;