import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { useEffect } from "react";

export default function CreateOrder({ createOrderOpen, setCreateOrderOpen }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  useEffect(() => {
    if (createOrderOpen == true) {
      onOpen();
    }
  }, [createOrderOpen]);

  useEffect(() => {
    if (isOpen == false) {
      setCreateOrderOpen(false);
    }
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Skapa order
              </ModalHeader>
              <ModalBody>
                <p>
                  Använd urvalet av produkter i dokumentet för att lägga upp en
                  ny order. Välj integration för att lägga upp ordern i det
                  systemet.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Stäng
                </Button>
                <Button color="primary" onPress={onClose}>
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
