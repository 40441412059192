import { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useUpdate } from './updateContext';

function useAddressBook() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [addresses, setAddresses] = useState(null);
    const { setUpdateCount } = useUpdate();

    const fetchAddresses = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_ADDRESS_BOOK_URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while fetching addresses'
            }
            setAddresses(data.addresses);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid hämtning av adresser');
        } finally {
            setLoading(false);
        }
    }

    const addAddress = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_ADDRESS_BOOK_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while adding address'
            }
            setAddresses(data.addresses);
            toast.success('Adressen lades till.');
            setUpdateCount((prev) => prev + 1);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid skapande av adress');
        } finally {
            setLoading(false);
        }
    }

    const updateAddress = async (formData, addressId) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_ADDRESS_BOOK_URL}/${addressId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while updating address'
            }
            setAddresses(data.addresses);
            toast.success('Adressen uppdaterades.');
            setUpdateCount((prev) => prev + 1);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid uppdatering av adress');
        } finally {
            setLoading(false);
        }
    }

    const deleteAddress = async (addressId) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_ADDRESS_BOOK_URL}/${addressId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'An error occurred while deleting address')
            }
            setAddresses(data.addresses);
            toast.success('Adressen togs bort.');
            setUpdateCount((prev) => prev + 1);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid radering av adress');
        } finally {
            setLoading(false);
        }
    }
    return {
        loading,
        error,
        success,
        addresses,
        fetchAddresses,
        addAddress,
        updateAddress,
        deleteAddress,
    }
}

export { useAddressBook };