import {
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { MoonIcon } from "@heroicons/react/20/solid";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { TagIcon } from "@heroicons/react/24/outline";
import useFetchOrders from "../../contexts/orderHook";
import TableSkeleton, { SkeletonRow } from "./tableSkeleton";
import { useIntegrationData } from "../../contexts/integrationHook";
import { Chip } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { Switch } from "@nextui-org/react";
import ManageOrderTags from "../components/manageTagsModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function formatDateTime(dateString) {
  if (!dateString) {
    return "Laddar...";
  }

  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "UTC", // Viktigt: Visa tiden i UTC utan justering
  };

  return new Intl.DateTimeFormat("sv-SE", options)
    .format(date)
    .replace(/\//g, "-");
}

function DeliverableChip({ status }) {
  let bgColor = "bg-blue-50";
  let textColor = "text-blue-700";
  let borderColor = "ring-blue-700/10";
  let statusMessage = "Redo";

  if (status === "sent") {
    bgColor = "bg-green-50";
    textColor = "text-green-700";
    borderColor = "ring-green-600/20";
    statusMessage = "Skickad";
  } else if (status === "partial") {
    bgColor = "bg-blue-50";
    textColor = "text-blue-700";
    borderColor = "ring-blue-600";
    statusMessage = "Dellevererad";
  } else if (status === "cancelled") {
    bgColor = "bg-red-50";
    textColor = "text-red-700";
    borderColor = "ring-red-600/20";
    statusMessage = "Avbruten";
  } else if (status === "refunded") {
    bgColor = "bg-yellow-50";
    textColor = "text-yellow-800";
    borderColor = "ring-yellow-600/20";
    statusMessage = "Återbetald";
  } else if (status === "external") {
    bgColor = "bg-yellow-50";
    textColor = "text-yellow-800";
    borderColor = "ring-yellow-600/20";
    statusMessage = "Extern";
  } else if (status === "shared") {
    bgColor = "bg-yellow-50";
    textColor = "text-yellow-800";
    borderColor = "ring-yellow-600/20";
    statusMessage = "Delar";
  }

  return (
    <span
      className={`inline-flex ring-ye items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${borderColor}`}
    >
      {statusMessage}
    </span>
  );
}

function AdditionalChip({ status }) {
  let bgColor = "bg-gray-50";
  let textColor = "text-gray-600";
  let borderColor = "ring-gray-500/10";
  let statusMessage = "Okänd";

  if (status === "external") {
    bgColor = "bg-yellow-100";
    textColor = "text-yellow-800";
    statusMessage = "Extern";
  } else if (status === "shared") {
    bgColor = "bg-indigo-100";
    textColor = "text-indigo-700";
    statusMessage = "Delar";
  }

  return (
    <span
      className={`inline-flex items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}
    >
      {statusMessage}
    </span>
  );
}

function Pagination({ currentPage, totalPages, onPageChange }) {
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    const delta = 2; // Antal sidor att visa före och efter den aktuella sidan

    // Visa alltid de första och sista sidorna
    const range = Array.from({ length: totalPages }, (_, i) => i + 1);

    // Bygg reducerad lista med sidnummer
    range.forEach((page) => {
      if (
        page === 1 || // Första sidan
        page === totalPages || // Sista sidan
        (page >= currentPage - delta && page <= currentPage + delta) // Några sidor runt den aktuella
      ) {
        pages.push(page);
      } else if (pages[pages.length - 1] !== "…") {
        pages.push("…");
      }
    });

    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <nav className="flex items-center justify-between border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${
            currentPage === 1
              ? "text-gray-300"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
          Föregående
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {/* Här kan du lägga till logik för att visa sidnummer */}
        {pageNumbers.map((page, index) => (
          <button
            key={index}
            onClick={() => page !== "…" && onPageChange(page)}
            disabled={page === "…"}
            className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
              currentPage === page
                ? "border-indigo-500 text-indigo-600"
                : page === "…"
                ? "text-gray-400"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
            }`}
          >
            {page}
          </button>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium ${
            currentPage === totalPages
              ? "text-gray-300"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
        >
          Nästa
          <ArrowLongRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
}

export default function Orders() {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [addedTags, setAddedTags] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(
    localStorage.getItem("selectedIntegration") || ""
  );
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("searchTerm") || ""
  );
  const [country, setCountry] = useState(
    localStorage.getItem("country") || "all"
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("page"), 10) || 1
  );
  const [status, setStatus] = useState(localStorage.getItem("status") || "all");
  const [external, setExternal] = useState(
    localStorage.getItem("external") === "true"
  );
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [direction, setDirection] = useState("desc");
  const [totalPages, setTotalPages] = useState(1);

  const { integrationData, loading: loadingIntegrations } =
    useIntegrationData();
  const { loading, error, orderData, metaData, fetchOrders } = useFetchOrders();

  const memoizedFetchOrders = useCallback(fetchOrders, []);
  const debouncedFetchOrders = useMemo(
    () => debounce((params) => memoizedFetchOrders(params), 500),
    [memoizedFetchOrders]
  );

  useEffect(() => {
    // Spara status till localStorage när det ändras
    localStorage.setItem("status", status);
  }, [status]);

  useEffect(() => {
    console.log(selectedPeople);
  }, [selectedPeople]);

  useEffect(() => {
    localStorage.setItem("country", country);
  }, [country]);

  useEffect(() => {
    localStorage.setItem("selectedIntegration", selectedIntegration);
  }, [selectedIntegration]);

  useEffect(() => {
    localStorage.setItem("searchTerm", searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    localStorage.setItem("page", currentPage);
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("external", external.toString());
  }, [external]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    debouncedFetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: 1,
      external,
    });
  };

  const handleIntegrationChange = (event) => {
    const integration = event.target.value;
    setSelectedIntegration(integration);
    setCurrentPage(1);
    fetchOrders({
      search: searchTerm,
      integrationName: integration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: 1,
      external,
    });
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setCountry(country);
    setCurrentPage(1);
    fetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: 1,
      external,
    });
  };

  const handleStatusChange = (event) => {
    const status = event.target.value;
    setStatus(status);
    setCurrentPage(1);
    fetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: 1,
      external,
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: page,
      external,
    });
  };

  const handleExternalChange = (event) => {
    const external = event.target.checked;
    setExternal(external);
    fetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: 1,
      external: external,
    });
  };

  useEffect(() => {
    fetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: currentPage,
      external,
    });
  }, [dateRange, direction, currentPage]);

  useEffect(() => {
    if (addedTags === true) {
      fetchOrders({
        search: searchTerm,
        integrationName: selectedIntegration,
        country,
        status,
        date_range: dateRange,
        direction,
        page: currentPage,
        external,
      });
      setSelectedPeople([]);
    }
  }, [addedTags]); // Endast beroende av addedTags

  useEffect(() => {
    if (metaData) {
      setTotalPages(metaData.totalPages || 1);
    }
  }, [metaData]);

  useEffect(() => {
    if (orderData && Array.isArray(orderData)) {
      // Extrahera order-ID från den senaste datan
      const sequence = orderData.map((order) => order._id);
      // Spara i localStorage
      localStorage.setItem("orderSequence", JSON.stringify(sequence));
    }
  }, [orderData]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 && selectedPeople.length < orderData?.length;
    setChecked(
      selectedPeople.length === orderData?.length && orderData?.length > 0
    );
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedPeople, orderData]);

  const toggleAll = () => {
    setSelectedPeople(checked || indeterminate ? [] : orderData);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const handleFetchClick = () => {
    console.log("something");
  };

  const navigate = useNavigate();
  const handleRowClick = (orderId) => {
    navigate(`/dash/orders/${orderId}`);
  };

  return (
    <div className="flex flex-col space-x-0 space-y-12 sm:space-x-0 mt-4 mx-auto max-w-5xl items-start">
      <ManageOrderTags
        tagsOpen={tagsOpen}
        setTagsOpen={setTagsOpen}
        orders={selectedPeople}
        setAddedTags={setAddedTags}
      />
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <div className="flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Beställningar
          </h2>
        </div>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center sm:space-x-2">
          <div>
            <div className="max-w-md mx-auto">
              <div className="relative flex items-center w-full h-12 overflow-hidden">
                <div className="grid place-items-center h-full w-12 text-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
                <input
                  className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  type="text"
                  id="search"
                  placeholder="Sök beställning.."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div className="mt-2 sm:mt-0 sm:flex-none">
            <div>
              <select
                id="integration"
                name="integration"
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={selectedIntegration}
                onChange={handleIntegrationChange}
                disabled={loadingIntegrations}
              >
                <option value="">Alla integrationer</option>
                {integrationData?.map((integration) => (
                  <option key={integration._id} value={integration._id}>
                    {integration.integrationName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-2 sm:mt-0 sm:flex-none">
            <div>
              <select
                id="country"
                name="country"
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={country}
                onChange={handleCountryChange}
              >
                <option value="all">Alla länder</option>
                <option value="SE">Sverige</option>
                <option value="NO">Norge</option>
                {/* Lägg till fler länder vid behov */}
              </select>
            </div>
          </div>
          <div className="mt-2 sm:mt-0 sm:flex-none">
            <div>
              <select
                id="status"
                name="status"
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={status}
                onChange={handleStatusChange}
              >
                <option value="all">Alla beställningar</option>
                <option value="open">Ohanterade beställningar</option>
                <option value="cancelled">Avbrutna beställningar</option>
                <option value="sent">Skickade beställningar</option>
                {/* Lägg till fler statusar vid behov */}
              </select>
            </div>
          </div>
          <div className="mt-2 sm:mt-0 sm:flex-none">
            <Switch
              defaultSelected={external}
              onChange={handleExternalChange}
              size="sm"
            >
              Externa
            </Switch>
          </div>
        </div>
        <div className="mt-8 py-4 flow-root rounded-xl bg-white shadow">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                {selectedPeople.length > 0 && (
                  <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                    <button
                      type="button"
                      onClick={() => setTagsOpen(true)}
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      <TagIcon
                        className="mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Redigera taggar
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Hantera
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                )}
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className="lg:min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Order-ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Beställare
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Belopp
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {loading ? (
                      <SkeletonRow />
                    ) : orderData && orderData.length > 0 ? (
                      orderData.map((order) => {
                        return (
                          <tr
                            key={order._id}
                            onClick={() => handleRowClick(order._id)}
                            className={
                              selectedPeople.includes(order)
                                ? "bg-gray-100"
                                : "cursor-pointer hover:bg-gray-100"
                            }
                          >
                            <td className="relative px-7 sm:w-12 sm:px-6">
                              {selectedPeople.includes(order) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                              )}
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                value={order.email}
                                checked={selectedPeople.includes(order)}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) =>
                                  setSelectedPeople(
                                    e.target.checked
                                      ? [...selectedPeople, order]
                                      : selectedPeople.filter(
                                          (p) => p !== order
                                        )
                                  )
                                }
                              />
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
                              <div className="flex items-center gap-2">
                                {order.platformOrderId}
                                {/* Mappar igenom alla taggar */}
                                {order.tags?.map((tag) => (
                                  <div
                                    key={tag._id}
                                    className="w-3 h-3 rounded-full"
                                    style={{ backgroundColor: tag.color }}
                                    title={tag.name} // Tooltip för att visa taggnamnet
                                  />
                                ))}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4">
                              <div className="text-sm font-medium text-gray-900">
                                {order.billingAddress.company
                                  ? order.billingAddress.company
                                  : `${order.customer.firstName} ${order.customer.lastName}`}
                              </div>
                              <div className="text-sm text-gray-500">
                                {formatDateTime(order.createdAt)}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {order.customer.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {order.total_price} {order.total_price && "SEK"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="grid grid-cols-1 gap-y-1">
                                <div>
                                  <DeliverableChip status={order.status} />
                                </div>
                                {order.external === true && order.owned && (
                                  <div>
                                    <AdditionalChip status={"shared"} />
                                  </div>
                                )}
                                {order.external === true && !order.owned && (
                                  <div>
                                    <AdditionalChip status={"external"} />
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                              <a
                                href="#"
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Hantera
                                <span className="sr-only">, {order.name}</span>
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-5">
                          Inga beställningar hittades.
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot className="divide-y divide-gray-200 bg-white"></tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
