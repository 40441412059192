import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useDocuments from "../../contexts/documentHook";

export default function CommercialInvoice() {
  const { documentId } = useParams(); // Hämta documentId från URL:en
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");
  const uid = query.get("uid");
  const { loading, error, document, getDocument } = useDocuments();

  useEffect(() => {
    if (documentId) {
      getDocument(documentId, token, uid);
      console.log(token);
    }
  }, [documentId]);

  if (loading) return <p>Laddar...</p>;
  if (error) return <p>Ett fel inträffade: {error}</p>;
  if (!document) return <p>Ingen faktura hittades.</p>;

  const { sender, receiver, products, documentDate, currency, packageCount } =
    document.document;

  // Funktion för att hantera edited-fält
  const resolveField = (field, editedField) => editedField || field;

  // Beräkna totalbelopp och totalvikt
  const totalAmount = products.reduce(
    (sum, product) =>
      sum +
      parseFloat(resolveField(product.price, product.editedPrice)) *
        parseFloat(resolveField(product.quantity, product.editedQuantity)),
    0
  );
  const totalWeight = products
    .reduce(
      (sum, product) =>
        sum +
        parseFloat(resolveField(product.weight, product.editedWeight)) *
          parseFloat(resolveField(product.quantity, product.editedQuantity)),
      0
    )
    .toFixed(2);

  // Exempel-värden. Du kanske redan har these i ditt dokument:
  const otherCosts = 0.0;
  const freightCost = 0.0;

  // Beräkna total value (ex: totAmount + otherCosts + freight)
  const totalValue = totalAmount + otherCosts + freightCost;

  return (
    <>
      {/* --- Utskriftsregler (rekommenderat att lägga i en CSS-fil, men exempelvis här inline) --- */}
      <style>{`
          /* Sätt A4 och lite marginaler för utskrift */
          @page {
            size: A4;
            margin: 15mm;
          }
  
          @media print {
            /* För att bättre styra färg och bakgrund */
            * {
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important; 
            }
            
            /* Ta bort skuggor, rundningar, ev. watermark om så önskas */
            .invoice-container {
              box-shadow: none !important;
              border-radius: 0 !important;
              margin: 0 !important;
              border: none !important;
            }
            .shadow-2xl, .shadow-sm {
              box-shadow: none !important;
            }
            .watermark {
              display: none !important; 
            }
  
            /* Hindra block eller rader från att brytas mitt i */
            .no-break {
              page-break-inside: avoid !important;
              break-inside: avoid !important;
            }
            table, thead, tbody, tfoot, tr, th, td {
              page-break-inside: avoid !important;
              break-inside: avoid !important;
            }
  
            /* Gör tabellen fullt synlig, även om den är bred */
            .overflow-x-auto {
              overflow: visible !important;
            }
          }
        `}</style>

      <div className="invoice-container max-w-3xl mx-auto p-8 bg-white text-black font-sans rounded-xl shadow-2xl relative">
        {/* --- Exempel på en watermark-klass som vi gömmer i print-läge --- */}
        <div className="watermark pointer-events-none select-none absolute inset-0 flex justify-center items-center">
          <p className="text-7xl font-bold text-gray-200 opacity-10 rotate-45">
            Powered by ...
          </p>
        </div>

        <div className="relative z-10">
          {/* Header */}
          <div className="flex justify-between mb-8 no-break">
            <div>
              <h1 className="text-4xl font-bold tracking-wide mb-1">
                Commercial Invoice
              </h1>
              <p className="text-sm">
                Shipping date: {new Date(documentDate).toLocaleDateString()}
              </p>
            </div>
            <div className="text-right">
              <p className="font-semibold">
                Invoice #: <span className="text-gray-700">{documentId}</span>
              </p>
              <p className="text-md">Currency: {currency}</p>
              <p className="text-md">Terms of sale: DAP</p>
            </div>
          </div>

          {/* Sender & Receiver – packa ihop i en container som vi vill undvika att bryta mitt i */}
          <div className="mb-8 flex flex-col md:flex-row md:justify-between md:space-x-8 space-y-4 md:space-y-0 no-break addresses">
            <div className="md:w-1/2 bg-gray-50 border border-gray-200 rounded-md p-4 shadow-sm">
              <h2 className="text-lg font-semibold mb-2">Sender</h2>
              <p className="text-sm">{sender.company}</p>
              <p className="text-sm">{sender.address1}</p>
              <p className="text-sm">{sender.address2}</p>
              <p className="text-sm">
                {sender.zip} {sender.city}
              </p>
              <p className="text-sm">{sender.countryCode}</p>
              <div className="mt-4">
                <p className="text-sm">VAT.No: {sender.vatNumber}</p>
                <p className="text-sm">Phone: {sender.phone}</p>
                <p className="text-sm">E-mail: {sender.email}</p>
              </div>
            </div>
            <div className="md:w-1/2 bg-gray-50 border border-gray-200 rounded-md p-4 shadow-sm">
              <h2 className="text-lg font-semibold mb-2">Receiver</h2>
              <p className="text-sm">{receiver.company}</p>
              <p className="text-sm">{receiver.address1}</p>
              <p className="text-sm">{receiver.address2}</p>
              <p className="text-sm">
                {receiver.zip} {receiver.city}
              </p>
              <p className="text-sm">{receiver.countryCode}</p>
              <div className="mt-4">
                <p className="text-sm">VAT.No: {receiver.vatNumber}</p>
                <p className="text-sm">Phone: {receiver.phone}</p>
                <p className="text-sm">E-mail: {receiver.email}</p>
              </div>
            </div>
          </div>

          {/* Produkt-tabell */}
          <div className="table-container overflow-x-auto no-break">
            <table className="w-full border-collapse text-sm mb-8 rounded-md shadow-sm">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="px-4 py-2 text-left">Product</th>
                  <th className="px-4 py-2 text-left">HS-code</th>
                  <th className="px-4 py-2 text-left">Origin</th>
                  <th className="px-4 py-2 text-right">Weight</th>
                  <th className="px-4 py-2 text-right">Qty</th>
                  <th className="px-4 py-2 text-right">Price</th>
                  <th className="px-4 py-2 text-right">Sum</th>
                  <th className="px-4 py-2 text-right">Total Weight</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {products.map((product, index) => {
                  const weight = parseFloat(
                    resolveField(product.weight, product.editedWeight)
                  );
                  const quantity = parseFloat(
                    resolveField(product.quantity, product.editedQuantity)
                  );
                  const price = parseFloat(
                    resolveField(product.price, product.editedPrice)
                  );
                  const rowWeight = (weight * quantity).toFixed(2);
                  const rowSum = (price * quantity).toFixed(2);

                  return (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-50"
                    >
                      <td className="px-4 py-2">
                        {resolveField(product.title, product.editedTitle)}
                      </td>
                      <td className="px-4 py-2">
                        {resolveField(product.hsCode, product.editedHsCode)}
                      </td>
                      <td className="px-4 py-2">
                        {resolveField(
                          product.countryOfOrigin,
                          product.editedCountryOfOrigin
                        )}
                      </td>
                      <td className="px-4 py-2 text-right">
                        {weight.toFixed(2)}
                      </td>
                      <td className="px-4 py-2 text-right">{quantity}</td>
                      <td className="px-4 py-2 text-right">
                        {price.toFixed(2)}
                      </td>
                      <td className="px-4 py-2 text-right">{rowSum}</td>
                      <td className="px-4 py-2 text-right">{rowWeight}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Summering / footer för totals */}
          <div className="flex flex-col md:flex-row md:justify-between items-start text-sm no-break">
            <div className="mb-4 md:mb-0">
              <div className="">
                <h3 className="font-semibold mb-1">Total number of packages</h3>
                <p>{packageCount || 1}</p>
              </div>
              <div className="mt-4">
                <h3 className="font-semibold mb-1">Total weight</h3>
                <p>{totalWeight} kg</p>
              </div>
            </div>
            <div className="bg-gray-50 border border-gray-200 rounded-md p-4 shadow-sm w-full md:w-1/3 text-sm">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td className="py-1 text-gray-700 font-semibold">
                      Other costs:
                    </td>
                    <td className="py-1 text-right">
                      {otherCosts.toFixed(2)} {currency}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-1 text-gray-700 font-semibold">
                      Freight cost:
                    </td>
                    <td className="py-1 text-right">
                      {freightCost.toFixed(2)} {currency}
                    </td>
                  </tr>
                  <tr className="border-t border-gray-300">
                    <td className="py-1 text-gray-800 font-bold">
                      Total value:
                    </td>
                    <td className="py-1 text-right font-bold">
                      {totalValue.toFixed(2)} {currency}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="my-8 no-break">
            <h2 className="text-lg font-semibold mb-4">Declaration</h2>
            <p className="text-sm mb-4">
              I hereby declare that the information given in this invoice is
              true and correct.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label className="block font-semibold text-sm mb-1">
                  Signature
                </label>
                <div className="h-8 border-b border-gray-400 w-full"></div>
              </div>
              <div>
                <label className="block font-semibold text-sm mb-1">
                  Title
                </label>
                <div className="h-8 border-b border-gray-400 w-full"></div>
              </div>
              <div>
                <label className="block font-semibold text-sm mb-1">Date</label>
                <div className="h-8 border-b border-gray-400 w-full"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Snygg footer (visas även vid utskrift, om du vill) */}
        <footer className="mt-8 pt-4 border-t border-gray-300 text-center text-xs text-gray-500 relative z-10">
          <p>Powered by Flowtrade - www.flowtrade.se</p>
        </footer>
      </div>
    </>
  );
}
