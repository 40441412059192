import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  Button,
  useDisclosure,
  SelectItem,
  Checkbox,
  Tooltip,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import useExternal from "../../contexts/externalHook";
import { useUpdateOrder } from "../../contexts/orderHook";

export default function ShareModal({
  openState,
  setOpenState,
  orderId,
  externalUserIds,
  owned,
  resetStock,
}) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { fetchExternal, externalEnvironments, loading, error } = useExternal();
  const {
    setExternalOrder,
    loading: updateLoading,
    error: updateError,
    success,
  } = useUpdateOrder();
  const [value, setValue] = useState(null);
  const [restoreStock, setRestoreStock] = useState(true);

  const handleSubmit = async () => {
    try {
      const externalUserIds = value ? [value] : [];
      await setExternalOrder(orderId, externalUserIds, restoreStock);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRestoreChange = (event) => {
    const newValue = event.target.checked;
    setRestoreStock(newValue);
  };

  useEffect(() => {
    if (externalUserIds && externalUserIds.length > 0) {
      setValue(externalUserIds[0].userId);
      setRestoreStock(resetStock);
    }
  }, [externalUserIds]);

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success]);

  useEffect(() => {
    fetchExternal();
  }, []);

  useEffect(() => {
    if (openState == true) {
      onOpen();
    }
  }, [openState]);

  useEffect(() => {
    if (isOpen == false) {
      setOpenState(false);
    }
  }, [isOpen]);

  const handleSelectionChange = (value) => {
    setValue(value);
    console.log(value);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Dela med extern miljö
              </ModalHeader>
              <ModalBody>
                <p className="text-medium">
                  Gör den här ordern tillgänglig för en annan organisation i
                  Flowtrade. Hantera kopplade konton i inställningar.
                </p>
                {owned && (
                  <>
                    <Select
                      label="Välj extern miljö"
                      placeholder="Ingen extern åtkomst"
                      className="max-w-full"
                      onChange={(e) => handleSelectionChange(e.target.value)}
                      defaultSelectedKeys={externalUserIds?.map(
                        (env) => env.userId
                      )}
                    >
                      {externalEnvironments?.map((env) => (
                        <SelectItem key={env.userId} value={env.userId}>
                          {env.companyName}
                        </SelectItem>
                      ))}
                    </Select>
                    {value && (
                      <Checkbox
                        defaultSelected={restoreStock}
                        onChange={handleRestoreChange}
                      >
                        Återför lagersaldon vid extern leverans
                      </Checkbox>
                    )}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Stäng
                </Button>
                <Button
                  color="primary"
                  onPress={handleSubmit}
                  isDisabled={loading || updateLoading}
                  isLoading={loading || updateLoading}
                >
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
