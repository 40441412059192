import { useState, useEffect } from 'react';
import { useUpdate } from './updateContext';
import toast, { Toaster } from 'react-hot-toast';

function useFetchBookings() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [bookings, setBookings] = useState(null);
    const [bookingData, setBookingData] = useState(null);
    const [success, setSuccess] = useState(null);
    const [rejection, setRejection] = useState(null);

    const { setUpdateCount } = useUpdate();

    const fetchBookings = async (params = {}) => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('site');
            const url = new URL(process.env.REACT_APP_BOOKINGS_URL);  // Se till att URL:en finns i dina miljövariabler

            Object.keys(params).forEach(key => {
                if (params[key] !== undefined && params[key] !== null) {
                    url.searchParams.append(key, params[key]);  // Konverterar värden till query-parametrar
                }
            });

            const response = await fetch(url.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                // Kastar ett fel om responsen inte är OK
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setBookings(data || []);  // Sätter data från servern
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid hämtning av upphämtningar');  // Visar ett felmeddelande
        } finally {
            setLoading(false);  // Sätter loading till false när vi är klara
        }
    }

    const addBooking = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);
        setRejection(null);

        const loadingToastId = toast.loading('Skapar bokning...');

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_ADD_BOOKING_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (!response.ok) {
                setRejection(data.note);
                throw new Error(data.message || 'Ett fel inträffade');
            }
            setBookingData(data);
            setSuccess(true);
            setUpdateCount((prev) => prev + 1);
            toast.success('Bokningen skapades!', { id: loadingToastId, duration: 4000 });
        } catch (error) {
            setError(error.message);
            toast.error('Ett fel inträffade', { id: loadingToastId, duration: 4000 });
        } finally {
            setLoading(false);
        }
    }
    return { addBooking, fetchBookings, loading, error, bookings, bookingData, success, rejection };
}

function useFetchShipments() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [shipments, setShipments] = useState(null);

    // Modifierad för att acceptera en sökterm
    const fetchShipments = async (params = {}) => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('site');
            const url = new URL(process.env.REACT_APP_SHIPMENTS_URL);  // Se till att URL:en finns i dina miljövariabler

            // Lägg till query-parametrar
            Object.keys(params).forEach(key => {
                if (params[key] !== undefined && params[key] !== null) {
                    if (typeof params[key] === 'object') {
                        url.searchParams.append(key, JSON.stringify(params[key]));
                    } else {
                        url.searchParams.append(key, params[key]);
                    }
                }
            });

            const response = await fetch(url.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                // Kastar ett fel om responsen inte är OK
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setShipments(data || []);  // Sätter data från servern
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid hämtning av frakter');  // Visar ett felmeddelande
        } finally {
            setLoading(false);  // Sätter loading till false när vi är klara
        }
    };

    const fetchShipmentsV2 = async (params = {}) => {
        setLoading(true);
        setError(null);
        setShipments(null);

        try {
            const token = localStorage.getItem('site');
            const url = new URL(process.env.REACT_APP_SHIPMENTS_V2_URL);  // Se till att URL:en finns i dina miljövariabler

            // Lägg till query-parametrar
            Object.keys(params).forEach(key => {
                if (params[key] !== undefined && params[key] !== null) {
                    if (typeof params[key] === 'object') {
                        url.searchParams.append(key, JSON.stringify(params[key]));
                    } else {
                        url.searchParams.append(key, params[key]);
                    }
                }
            });

            const response = await fetch(url.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                // Kastar ett fel om responsen inte är OK
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setShipments(data || []);  // Sätter data från servern
        } catch (error) {
            setError(error.message);
            console.log(error.message);
            toast.error('Fel vid hämtning av frakter');  // Visar ett felmeddelande
        } finally {
            setLoading(false);  // Sätter loading till false när vi är klara
        }
    };

    // Returnerar fetchShipments så att den kan anropas med en sökterm
    return { fetchShipments, fetchShipmentsV2, loading, error, shipments };
}

export default useFetchShipments;

export { useFetchBookings };