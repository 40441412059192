import React from "react";
import { useState, useEffect } from "react";
import { Addresses } from "./pickupAddresses";
import { Packages } from "./packageSizes";
import { ExternalEnvironments } from "./externalEnvironmens";
import { AddressBook } from "./addresses";

import Tags from "./tags";
import { Switch } from "@nextui-org/react";

const GeneralSettings = () => {
  const [open, setOpen] = useState(false); // Hantera öppningen lokalt
  const [enabled, setEnabled] = useState(false); // Hantera testläge
  const [packageOpen, setPackageOpen] = useState(false);
  const [externalOpen, setExternalOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);

  const handleStateChange = () => {
    setOpen(true); // Öppna Drawer när knappen trycks
  };

  const handleClose = () => {
    setOpen(false); // Stäng Drawer
  };

  const handleExternalState = () => {
    setExternalOpen(true);
  };

  const handleExternalClose = () => {
    setExternalOpen(false);
  };

  const handlePackageState = () => {
    setPackageOpen(true);
  };

  const handlePackageStateClose = () => {
    setPackageOpen(false);
  };

  const handleTagOpen = () => {
    setTagsOpen(true);
  };

  const handleTagClose = () => {
    setTagsOpen(false);
  };

  const handleAddressOpen = () => {
    setAddressOpen(true);
  };

  const handleAddressClose = () => {
    setAddressOpen(false);
  };

  useEffect(() => {
    // Läs av URL-parametrarna
    const queryParams = new URLSearchParams(window.location.search);

    // Kolla om stateOpen är satt till 'true'
    if (queryParams.get("tagsOpen") === "true") {
      setTagsOpen(true);
    }
  }, []);

  return (
    <>
      <ExternalEnvironments open={externalOpen} onClose={handleExternalClose} />
      <Addresses open={open} onClose={handleClose} />
      <Packages open={packageOpen} onClose={handlePackageStateClose} />
      <Tags open={tagsOpen} onClose={handleTagClose} />
      <AddressBook open={addressOpen} onClose={handleAddressClose} />
      <div className="px-4 sm:px-6 lg:px-8 pt-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Allmänna inställningar
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Hantera organisationens allmänna inställningar.
            </p>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-x-6 sm:gap-y-4">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-2">
              <div className="bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-4">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Upphämtningsadresser
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Hantera organisationens upphämtningsadresser och
                      inställningar.
                    </p>
                  </div>
                  <div className="ml-4 mt-4 flex-shrink-0">
                    <button
                      type="button"
                      onClick={handleStateChange}
                      className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Hantera
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-2">
              <div className="bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-4">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Externa miljöer
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Hantera externa Flowtrade-miljöer för att kunna samarbeta
                      med andra organisationer.
                    </p>
                  </div>
                  <div className="ml-4 mt-4 flex-shrink-0">
                    <button
                      type="button"
                      onClick={handleExternalState}
                      className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Hantera
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-2">
              <div className="bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-4">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Flowprint®
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Hantera organisationens kopplade plockstationer och
                      skrivare.
                    </p>
                  </div>
                  <div className="ml-4 mt-4 flex-shrink-0">
                    <button
                      type="button"
                      className="relative inline-flex items-center rounded-md bg-indigo-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Aktivera
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-2">
              <div className="bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-4">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Paketmallar
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Aktivera och hantera förinställda paketdimensioner.
                    </p>
                  </div>
                  <div className="ml-4 mt-4 flex-shrink-0">
                    <button
                      type="button"
                      onClick={handlePackageState}
                      className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Hantera
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-2">
              <div className="bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-4">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Taggar
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Märk organisationens beställningar med anpassade taggar.
                    </p>
                  </div>
                  <div className="ml-4 mt-4 flex-shrink-0">
                    <button
                      type="button"
                      onClick={handleTagOpen}
                      className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Hantera
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-2">
              <div className="bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-4">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Adressbok
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Se och ändra dina sparade avsändare och mottagare.
                    </p>
                  </div>
                  <div className="ml-4 mt-4 flex-shrink-0">
                    <button
                      type="button"
                      onClick={handleAddressOpen}
                      className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Hantera
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettings;
